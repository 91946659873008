import { ReactComponent as Logo } from 'images/logo.svg';
import { Link } from 'react-router-dom';

const UnAuthorisedHeader = (props) => {
    return (
        <header className="unauthorised-header">
            <Link to="/" className="unauthorised-header__logo"><Logo/></Link>
            <h1 className="unauthorised-header__strapline">My Account</h1>
        </header>
    )
}

export default UnAuthorisedHeader;