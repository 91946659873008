import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import MenuContext from 'context/menu';
import NavigationContext from 'context/navigation';
import Icon from 'components/ui/Icon';
import { useHistory } from 'react-router-dom';
import { getNavigation } from 'helper/customer';
import MockData from 'components/ui/MockData';
import { pathMatches } from 'helper/path';
import { getClassName } from 'helper/bem';

const NavItem = (props) => {
    const location = useLocation();
    const history = useHistory();
    const menuContext = useContext(MenuContext);
    const icon = (props.item.icon ? <Icon icon={ props.item.icon } className="sidenav__list-item-link-icon" /> : '');
    const logOut = () => {
        menuContext.setMenuOpen(false);
        props.logout();
    }
    const navLinkClick = (event) => {
        menuContext.setMenuOpen(false);
        if (location.pathname !== event.currentTarget.dataset.path) {
            history.push(event.currentTarget.dataset.path);
        }
    }
    if (props.item.logout) {
        return <button className="sidenav__list-item-link" onClick={ logOut }>{ icon }{ props.item.title }</button>
    } else if (props.item.path && props.item.path.substr(0, 4) === 'http') {
        if (props.item.path === 'https://www.flogas.co.uk') {
            props.item.path = props.flogasUrl;
        }
        return <a className="sidenav__list-item-link" href={ props.item.path } target="portalExternal">{ icon }{ props.item.title }<Icon icon="External" className="sidenav__list-item-link-icon sidenav__list-item-link-icon--external" /></a>
    } else {
        if ((!process.env.REACT_APP_NAV_TEST_MODE || process.env.REACT_APP_NAV_TEST_MODE === 'false') && !props.item.permission) {
            return null;
        }
        return <button onClick={ navLinkClick } className="sidenav__list-item-link" data-path={ '/' + (props.item.childPath ? props.item.childPath : props.item.path) }>{ icon }{ props.item.title } { (!props.item.permission ? ' *' : '') }</button>
    }
}


const Nav = () => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const navigationContext = useContext(NavigationContext);
    const location = useLocation();
    const flogasUrl = 'https://www.flogas.co.uk' + (userContext.user.sector === 'commercial' ? '/business' : '');
    const getNav = () => {
        if (authContext.authenticated && navigationContext.navConfig.length) {
            return (
                <>
                <ul className="sidenav__list">
                    { Object.entries(getNavigation(navigationContext.navConfig, userContext.user, 'navigation')).map(([index, item]) => {
                        return (
                            <li key={ index } className={ 'sidenav__list-item' + (pathMatches(location, item.path) ? ' sidenav__list-item--active' : '') }>
                                <NavItem className="sidenav__list-item-link" index={ index } item={ item } />
                            </li>
                        )
                    }) }
                </ul>
                <ul className="sidenav__list sidenav__list--secondary">
                    { Object.entries(getNavigation(navigationContext.navConfig, userContext.user, 'secondary')).map(([index, item]) => {
                        return (
                            <li key={ index } className={ getClassName('sidenav__list-item', (item.logout ? 'logout' : '') + (item.path && !item.path.includes('/') && pathMatches(location, item.path) ? ' active' : '')) }>
                                <NavItem className="sidenav__list-item-link" index={ index } item={ item } logout={ authContext.doLogOut } flogasUrl={ flogasUrl } />
                            </li>
                        )
                    }) }
                    {/*<li className="sidenav__list-item">
                        <NavItem className="sidenav__list-item-link" index="shopUrl" item={ { path: getFlogasUrl(), title: 'Visit Flogas.co.uk', newWindow: true } } />
                    </li>*/}
                </ul>
                </>
            );
        } else {
            const MockDataProps = { randomise: true, minWidth: 60 };
            return (
                <>
                <ul className="sidenav__list">
                    { [...Array(6)].map((x, i) => {
                        return <li key={ i } className="sidenav__list-item"><MockData { ...MockDataProps } /></li>
                    }) }
                </ul>
                <ul className="sidenav__list sidenav__list--secondary">
                    { [...Array(6)].map((x, i) => {
                        return <li key={ i } className="sidenav__list-item"><MockData { ...MockDataProps } /></li>
                    }) }
                </ul>
                </>
            );
        }
    }
    return (
        <nav className="sidenav">
            <div className="sidenav__inner">
                { getNav() }
            </div>
        </nav>
    );
};

export default Nav;