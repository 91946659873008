import React from 'react';
import { getHtml } from 'helper/html';

const content = {
    content: null,
    set(content) {
        this.content = content;
    },
    get(identifier, defaultContent) {
        if (this.content !== null && typeof this.content[identifier] !== 'undefined' && String(this.content[identifier]).length) {
            return this.content[identifier];
        }
        return defaultContent;
    },
    getReplaced(content, replacements) {
        if (typeof content === 'object') {
            return content;
        }
        content = String(content);
        Object.entries(replacements).forEach(([replacement, replacementValue]) => {
            content = content.replace('{' + replacement + '}', replacementValue)
        });
        return content;
    },
    text(identifier, defaultText, replacements = {}) {
        return this.getReplaced(this.get(identifier, defaultText), replacements);
    },
    html(identifier, defaultText, replacements = {}, className = 'html-content') {
        let html = this.getReplaced(this.get(identifier, defaultText), replacements);
        // For JSX defaults
        if (typeof html === 'object') {
            return html;
        }
        return <div className={ className }>{ getHtml(this.getReplaced(html, replacements)) }</div>;
    }
}
export default content;