import React, { useContext } from 'react';
import UserContext from 'context/user';
import format from 'helper/format';

export const getBalance = (balance) => {
    return (balance < 0 ? '- ' : '') + format.currency(Math.abs(balance)) + ' ' + (balance > 0 ? 'credit' : (balance !== 0 ? 'debit' : ''));
}

const Balance = (props) => {
    const { balance } = useContext(UserContext);
    const className = 'balance balance--' + (balance >= 0 ? 'positive' : 'negative');
    return <span className={ className }>{ getBalance(balance) }</span>
}

export default Balance;