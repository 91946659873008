import React, { useContext, useEffect, useState } from 'react';
import AuthContext from 'context/auth';
import UserContext from 'context/user';
import AccountsContext from 'context/accounts';
import Button from 'components/ui/Button';
import { useHistory } from 'react-router-dom';
import FavouriteButton from 'components/ui/FavouriteButton';
import Icon from 'components/ui/Icon';
import log from 'helper/log';
import { getClassName } from 'helper/bem';
import { api } from 'helper/api/client';
import permission from 'helper/permission';
import { getType, getPostcode, filterAccountsCallback } from 'helper/account';
import emailMismatch from 'helper/emailMismatch';
import revalidate from 'helper/revalidate';

const HeaderAccount = (props) => {
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const { accounts, setAccounts } = useContext(AccountsContext);
    const [accountFilter, setAccountFilter] = useState('');
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    useEffect(() => {
        if (!userContext.user.isDefault) {
            setAccounts(userContext.user.accounts);
        }
    }, [userContext.user, setAccounts]);
    useEffect(() => {
        setFilteredAccounts(accountFilter ? accounts.filter(filterAccountsCallback, accountFilter) : accounts);
    }, [accounts, accountFilter]);
    if (!accounts || !accounts.length) {
        return null;
    }
    const currentAccount = accounts.find(account => account.current);
    const getFavourites = () => {
        return filteredAccounts.filter(account => account.favourite);
    }
    const getNonFavourites = () => {
        return filteredAccounts.filter(account => !account.favourite);
    }
    const accountFilterInput = (e) => {
        setAccountFilter(e.target.value);
    }
    const accountFilterClear = () => {
        setAccountFilter('');
    }
    const toggleAccountMenu = () => {
        props.setAccountMenuOpen(!props.accountMenuOpen);
    }
    const switchAccount = (event) => {
        userContext.setSwitching(true);
        api.post('customer/accounts/switch', { id: parseInt(event.currentTarget.dataset.id) })
            .then(response => {
                if (api.success(response)) {
                    if (response.data.response.customer && response.data.response.customer !== false) {
                        log.action('Customer account', 'Switched', 'customer_account');
                        authContext.setToken(response.data.response.token);
                        userContext.setUser(response.data.response.customer);
                        setAccounts(response.data.response.customer.accounts);
                        history.push('/overview');
                    } else if (typeof response.data.response.revalidate !== 'undefined') {
                        revalidate(response.data.response);
                    } else if (typeof response.data.response.emailMismatch !== 'undefined') {
                        emailMismatch(response.data.response);
                    }
                    userContext.setSwitching(false);
                }
            }).catch(error => {
            userContext.setSwitching(false);
        });
    }
    const addAccount = () => {
        props.setAccountMenuOpen(false);
        history.push('/my-account/add');
    }
    const getCurrentAccountName = () => {
        const hasAlias = (currentAccount.alias !== null && currentAccount.alias.length > 0);
        const className ='header__account-button-name' + (hasAlias ? ' header__account-button-name--alias' : '');
        if (hasAlias) {
            return (
            <span className={ className }>
                <em>{ currentAccount.alias }<br/></em>
                <span>{ getType(currentAccount.type) + currentAccount.accountNumber + getPostcode(currentAccount.postcode) }</span>
            </span>
            )

        } else {
            return (
                <span className={ className }>{ getType(currentAccount.type) + currentAccount.accountNumber + getPostcode(currentAccount.postcode) }</span>
            );
        }
    }
    const getAccountName = (account) => {
        const hasAlias = (account.alias !== null && account.alias.length > 0);
        return (
            <>
                { hasAlias && <em>{ account.alias }<br/></em> }
                <span>{ getType(account.type) + account.accountNumber + getPostcode(account.postcode) }</span>
            </>
        );
    }
    const getAccountSwitcher = (title, favourites) => {
        let keyPrefix = title.toLowerCase().replace(' ', '-') + '-';
        return (
            <>
                <li className="header__account-menu-list-item header__account-menu-list-item--heading">{ title }</li>
                { favourites && favourites.map((account) => {
                    let aliasClass = (account.alias !== null && account.alias.length ? ' alias' : '');
                    return (<li key={ keyPrefix + account.id } className="header__account-menu-list-item  header__account-menu-list-item--account">
                        <FavouriteButton account={ account } accounts={ accounts } className="header-account-menu"/>
                        { account.current && <span title="This is the current account" className={ getClassName('header__account-menu-list-item-link', 'current' + aliasClass) }>{ getAccountName(account) }</span> }
                        { !account.current && <Button
                            title="Switch to this account"
                            fullClassName={ getClassName('header__account-menu-list-item-link', aliasClass) }
                            data-id={ account.id }
                            action={ switchAccount }
                        >{ getAccountName(account) }</Button> }
                    </li>)
                })
                }
            </>
        )
    }
    return (
        <>
            <div className={ 'header__account' + (props.accountMenuOpen ? ' header__account--open' : '') } ref={ props.forwardRef }>
                <button onClick={ toggleAccountMenu } className={ 'header__account-button' + (currentAccount.alias !== null && currentAccount.alias.length > 0 ? ' header__account-button--alias' : '') } aria-expanded={ props.accountMenuOpen }>
                    <span className="header-title">Account</span>
                    <Icon icon="ArrowDown" className="header__account-button-arrow"/>
                    { getCurrentAccountName() }
                </button>
                <div className={ 'header__account-menu' + (currentAccount.alias !== null && currentAccount.alias.length > 0 ? ' header__account-menu--alias' : '') }>
                    <div className="header__account-menu-search">
                        <input className="header__account-menu-search__input form__field-control form__field-control--text" value={ accountFilter } onChange={ accountFilterInput } placeholder="Search…"/>
                        <button className="form__field-control__clear-field" style={!accountFilter.length ? {display: 'none'} : {}} type="button" tabIndex="-1" onClick={ accountFilterClear }><Icon icon="close"/></button>
                    </div>
                    <ul className="header__account-menu-list">
                        { getFavourites().length > 0 && getAccountSwitcher('Favourites', getFavourites()) }
                        { getNonFavourites().length > 0 && getAccountSwitcher('All accounts', getNonFavourites()) }
                        { filteredAccounts.length === 0 && <>
                            <li className="header__account-menu-list-item header__account-menu-list-item--heading">All accounts</li>
                            <li key="search-empty" className="header__account-menu-list-item  header__account-menu-list-item--empty">No accounts match your search</li>
                        </> }
                        { permission.validRoute('/my-account/add') && <li className="header__account-menu-list-item header__account-menu-list-item--add"><Button onClick={ addAccount } simple={ true }>+ Add account</Button></li> }
                    </ul>
                </div>
            </div>
        </>
    );
}
export default HeaderAccount;