import { useEffect, useState } from 'react';
import NotificationMessage from 'components/ui/NotificationMessage';
import { useLocation } from 'react-router-dom';
import 'url-search-params-polyfill';

const GlobalNotificationMessage = (props) => {
    const [message, setMessage] = useState('');
    //const [type, setType] = useState('info');
    const type = 'info';
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let notificationMessage = params.get('notification');
    useEffect(() => {
        if (notificationMessage) {
            setMessage(notificationMessage);
        } else {
            setMessage('');
        }
    }, [notificationMessage]);
    if (message.length) {
        return <NotificationMessage message={ message } type={ type } className={ (props.globalClass ? 'global' : '') } />
    }
    return null;
}

export default GlobalNotificationMessage;