import PropTypes from 'prop-types';
import MockParagraph from 'components/ui/MockParagraph';

const Hero = (props) => {
    let className = 'hero' + (props.class ? ' ' + props.class : '');
    if (props.loading) {
        className += ' hero--loading';
    }
    let title = (props.title ? props.title : (props.route.title ? props.route.title : ''));
    let text = (props.text ? props.text : (props.route ? props.route.text : ''));
    return (
        <div className={ className }>
            { !props.loading && <h1 className="hero__title">{ title }</h1> }
            { props.loading && <MockParagraph items={ 5 } element="h1"/> }
            { !props.loading && text && text.length && <p className="hero__text">{ text }</p> }
        </div>
    )
}

Hero.defaultProps = {
    title: '',
    route: {
        title: ''
    }
};
Hero.propTypes = {
    title: PropTypes.string,
    route: PropTypes.object,
}

export default Hero;