import React from 'react';

import { getClassName } from 'helper/bem';

import FormField from 'components/form/Field';

const FormFieldList = (props) => {
    let className = getClassName('form__fields', props.className);
    const getFormFields = () => {
        return Object.entries(props.fields).map(([fieldName, formField]) => {
            const className = fieldName.substring(0, 1).toLowerCase()
                + fieldName.substring(1).replace(/([A-Z])/g, (m, w) => '-' + w.toLowerCase());
            return <FormField key={ formField.name } { ...formField }
                              formFields={ props.formFields }
                              className={ className }
                              value={ props.fieldValues[fieldName] }
                              valid={ props.fieldValidation[fieldName].valid }
                              disabled={ props.fieldsDisabled[fieldName] }
                              validationMessage={ props.fieldValidation[fieldName].validationMessage }
                              fieldValues={ props.fieldValues }
                              setFieldValues={ props.setFieldValues }
                              fieldVisibility={ props.fieldVisibility }
                              visible={ props.fieldVisibility[fieldName] }
                              fieldsDisabled={ props.fieldsDisabled }
                              fieldValidation={ props.fieldValidation }
                              inputChangeHandler={ props.inputChangeHandler }
                              showSubmit={ props.showSubmit }
                              getSubmit={ props.getSubmit }
                              inputFocusHandler={ props.inputFocusHandler }
            />
        });
    }
    if (props.filter || !props.className) {
        return <>
            { getFormFields() }
            { props.showSubmit && props.getSubmit() }
        </>
    }
    return (
        <div className={ className }>
            { getFormFields() }
            { props.showSubmit && props.getSubmit() }
        </div>
    );
};

export default FormFieldList;