import React from 'react';
import Password from 'components/form/field/Password';
import { validationItems } from 'helper/password';
import PasswordStrengthMessage from 'components/ui/PasswordStrengthMessage';

const PasswordStrength = (props) => {
    const password = String(props.value);

    const getValidationDisplay = function() {
        const rows = [];
        for (let validationItemKey in validationItems) {
            let validationItem = validationItems[validationItemKey];
            rows.push(
                <PasswordStrengthMessage key={validationItemKey} valid={validationItem.validator(password)} message={validationItem.message.substring(0, 1).toUpperCase() + validationItem.message.substring(1)} />
            );
        }
        return <ul className="form__field-password-strength">{rows}</ul>
    };
    return <>
        <Password { ...props } />
        { getValidationDisplay() }
    </>
};

export default PasswordStrength;