import React, { useState, createContext } from 'react';

const defaults = {
    accounts: [],
    setAccounts: () => {}
}
const AccountsContext = createContext(defaults);

export const AccountsContextProvider = (props) => {
    const [accounts, setAccounts] = useState(defaults.accounts);

    return <AccountsContext.Provider
        value={ {
            accounts: accounts,
            setAccounts: setAccounts,
        } }>{ props.children }</AccountsContext.Provider>;
}

export default AccountsContext;