import Icon from './Icon';

const PasswordStrengthMessage = (props) => {
    const { valid, message, useInvalid } = props;
    return <li className={'form__field-password-strength__item ' + (valid ? 'form__field-password-strength__item--valid' : (useInvalid ? 'form__field-password-strength__item--invalid' : ''))}>
        <Icon icon={valid ? 'Check' : 'Cross'} className="form__field-password-strength__item-icon"/>
        { message}
    </li>
}

export default PasswordStrengthMessage;