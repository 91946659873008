import permission from 'helper/permission';

export const getNavigation = (navigation, customer, position) => {
    if (customer.isDefault) {
        return [];
    }
    let firstChild;
    if (position) {
        navigation = navigation.filter((navigationItem, index) => navigationItem.position === position);
    }
    navigation = navigation.map(navigationItem => {
        navigationItem.permission = (navigationItem.logout || permission.validRoute(navigationItem.path));
        if (navigationItem.children.length) {
            navigationItem.children = getSubNavigation(navigationItem.children, customer, navigationItem.path);
            firstChild = navigationItem.children.find(child => child.permission);
            if (firstChild) {
                navigationItem.childPath = navigationItem.path + '/' + firstChild.path;
            } else {
                navigationItem.permission = false;
            }
        }
        return navigationItem;
    });
    return navigation;
}
export const getSubNavigation = (navigation, customer, basePath) => {
    if (customer.isDefault) {
        return [];
    }
    navigation = navigation.map(navigationItem => {
        navigationItem.permission = permission.validRoute(basePath + '/' + navigationItem.path);
        return navigationItem;
    });
    return navigation;
}
export const getRoutes = (routes) => {
    if (permission.permissions !== null) {
        return routes.filter(route => {
            return permission.validRoute(route.path);
        });
    }
    return routes;
}