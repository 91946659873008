import React, { createContext, useState, useEffect, useContext } from 'react';
import { api } from 'helper/api/client';
import AuthContext from 'context/auth';
import url from 'helper/url';

const defaults = {
    navConfig: []
}
const NavigationContext = createContext(defaults);

export const NavigationContextProvider = (props) => {
    const authContext = useContext(AuthContext);
    const [navConfig, setNavConfig] = useState(defaults.navConfig);
    useEffect(() => {
        if (authContext.authenticated) {
            api.get('navigation/list')
                .then(response => {
                    setNavConfig(response.data.response.navigation);
                    url.set(response.data.response.navigation);
                }).catch(error => {
                api.error(error);
            });
        }
    }, [authContext.authenticated]);

    return <NavigationContext.Provider
        value={ {
            navConfig: navConfig
        } }>{ props.children }</NavigationContext.Provider>;
}

export default NavigationContext;