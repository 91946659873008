const url = {
    navigation: null,
    set(navigation) {
        this.navigation = navigation;
    },
    get(type, slug) {
        if (type === 'faq') {
            const navItem = this.navigation.find(navItem => {
                return navItem.children.length && navItem.children.find(child => child.path === 'faqs');
            });
            return '/' + navItem.path + '/faqs?faq=' + slug;
        } else if (type === 'page') {
            const navItem = this.navigation.find(navItem => {
                return navItem.children.length && navItem.children.find(child => child.path === 'page/' + slug);
            });
            if (navItem) {
                return '/' + navItem.path + '/page/' + slug;
            } else {
                return '/page/' + slug;
            }
        } else {
            return '/' + slug;
        }
    },
    isFull(href) {
        return /^(https?):\/\//.test(href);
    },
    isPortalBasePath(href) {
        if (this.isFull(href)) {
            return false;
        }
        const pathArray = href.split('/');
        if (pathArray.length >= 2) {
            if (this.navigation === null) {
                return true;
            }
            const navItem = this.navigation.find(navItem => {
                return navItem.path === pathArray[1];
            });
            return (navItem ? true : false);
        }
        return false;
    }
}

export default url;