const MockParagraph = (props) => {
    const rows = new Array((props.items && props.items < 100 ? parseInt(props.items) : 30));
    const Element = (props.element ? props.element : 'p');
    const min = 25;
    const max = 120;
    rows.fill(null);
    return (
        <Element className="mock-data mock-data--paragraph">
            { rows.map((value, index) => {
                let style = {
                    width: (max * (Math.floor(Math.random() * 100) / 100))
                }
                if (style.width < min) {
                    style.width = min;
                }
                style.width = style.width + 'px';
                return <span className="mock-data__inner" key={ index } style={ style }/>;
            }) }
        </Element>
    );
}

export default MockParagraph;