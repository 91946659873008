import React, { createContext, useState, useEffect } from 'react';

const defaults = {
    menuOpen: false,
    setMenuOpen: () => {}
};
const MenuContext = createContext(defaults);

export const MenuContextProvider = (props) => {
    const [menuOpen, setMenuOpen] = useState(defaults.menuOpen);
    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add('body--menu-open');
        } else {
            document.body.classList.remove('body--menu-open');
        }
    }, [menuOpen]);

    return <MenuContext.Provider
        value={ {
            menuOpen: menuOpen,
            setMenuOpen: setMenuOpen,
        } }>{ props.children }</MenuContext.Provider>;
}


export default MenuContext;