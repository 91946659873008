import React from 'react';
import PropTypes from 'prop-types';

import FormFieldList from 'components/form/FieldList';

const FieldSet = (props) => {
    const getFieldsForGroup = (fields) => {
        return Object.keys(props.formFields)
            .filter(key => fields.includes(key))
            .reduce((obj, key) => {
                obj[key] = props.formFields[key];
                return obj;
            }, {});
    }
    return (
        <FormFieldList { ...props} className={ props.className } fields={ getFieldsForGroup(props.fields) } />
    );
};

FieldSet.propTypes = {
    fields: PropTypes.array.isRequired, // array of string references to the form fields
}

export default FieldSet;