import debug from 'helper/debug';

const setting = {
    settings: null,
    set(settings) {
        this.settings = settings;
    },
    get(identifier, defaultValue) {
        if (this.settings === null) {
            debug.error('Settings not loaded before call', identifier);
        }
        if (this.settings !== null && typeof this.settings[identifier] !== 'undefined') {
            return this.settings[identifier];
        }
        return defaultValue;
    }
}
export default setting;