import React, { useContext, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer'
import AuthContext from 'context/auth';
import Modal from 'portal/Modal';
import setting from 'helper/setting';

const originalTitleTag = 'Flogas My Account';

const getRemaining = (remaining) => {
    if (remaining < 1) {
        remaining = 1;
    }
    return remaining + ' second' + (remaining === 1 ? '' : 's');
}

const AutoLogout = () => {
    const logoutWarningAfter = setting.get('autoLogout.warning', 15) * 60; // Seconds before showing logout warning
    const closeModalAfter = setting.get('autoLogout.modalTimeout', 90); // Seconds before modal window closes
    const authContext = useContext(AuthContext);
    const [secondsRemaining, setSecondsRemaining] = useState(closeModalAfter);
    const [modalOpen, setModalOpen] = useState(false);
    const [lastActive, setLastActive] = useState(null);

    const handleOnIdleFull = event => {
        document.title = originalTitleTag;
        setModalOpen(false);
        setSecondsRemaining(closeModalAfter);
        authContext.doLogOut(null, 'inactive');
    }
    const handleOnIdle = event => {
        const now = new Date();
        setLastActive(now.getTime());
        setModalOpen(true);
    }
    const idleTimerEvents = [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove',
    ];
    const idleTimerFull = useIdleTimer({
        timeout: 1000 * (logoutWarningAfter + closeModalAfter),
        onIdle: handleOnIdleFull,
        events: idleTimerEvents,
        debounce: 500
    });
    const { reset } = useIdleTimer({
        timeout: 1000 * logoutWarningAfter,
        onIdle: handleOnIdle,
        events: idleTimerEvents,
        debounce: 500
    });

    useEffect(() => {
        if (modalOpen) {
            const interval = setInterval(() => {
                const now = new Date();
                setSecondsRemaining(closeModalAfter - Math.round((now.getTime() - lastActive) / 1000));
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [idleTimerFull, modalOpen, secondsRemaining, lastActive, closeModalAfter]);

    useEffect(() => {
        if (modalOpen) {
            if (secondsRemaining > 0) {
                if (secondsRemaining < closeModalAfter) {
                    document.title = 'Auto logout in ' + getRemaining(secondsRemaining);
                }
            } else {
                document.title = originalTitleTag;
                setSecondsRemaining(closeModalAfter);
                setModalOpen(false);

                authContext.doLogOut(null, 'inactive');
            }
        } else {
            document.title = originalTitleTag;
        }
    }, [modalOpen, secondsRemaining, authContext, closeModalAfter]);

    const noAction = () => {
        document.title = originalTitleTag;
        setModalOpen(false);
        setSecondsRemaining(closeModalAfter);
        authContext.doLogOut();
    }
    const yesAction = () => {
        setSecondsRemaining(closeModalAfter);
        document.title = originalTitleTag;
        reset();
        idleTimerFull.reset();
        setModalOpen(false);
    }

    if (modalOpen) {
        return (
            <Modal
                open={ true }
                allowClose={ false }
                modifier="autologout"
                noAction={ noAction }
                yesAction={ yesAction }
                yesOption="Yes"
                noOption="No"
                title={ 'You will be logged out in ' + getRemaining(secondsRemaining) }
            ><p>Do you want to continue your session?</p></Modal>
        );
    }
    return null;
}

export default AutoLogout;