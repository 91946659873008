import React from 'react';
import FieldSet from 'components/form/FieldSet';
import Button from 'components/ui/Button';

// Used to check for the existence of a submit button in custom markup
export const isSubmit = (child) => {
    const submitTypes = ['button', 'input', Button];
    return React.isValidElement(child) && submitTypes.includes(child.type) && child.props.type === 'submit';
}
export const isFieldset = (child) => {
    return React.isValidElement(child) && child.type === FieldSet;
}
export const isTitle = (child) => {
    return React.isValidElement(child) && child.type === 'h3' && child.props.className === 'form__title';
}
export const getChildren = (child) => {
    if (child.props && typeof child.props.children !== 'undefined') {
        if (React.Children.count(child.props.children) && typeof child.props.children === 'object') {
            return child.props.children;
        }
    }
    return null;
}

// Check the children for a prop of showSubmit, used when <FieldSets> have a submit button so that main form doesn't show one
export const getChildrenHaveSubmit = (children) => {
    let childHas = false;
    for (const child of React.Children.toArray(children)) {
        const children = getChildren(child);
        if (isFieldset(child) && child.props.showSubmit === true) {
            return true;
        } else if (isSubmit(child)) {
            return true;
        } else if (children) {
            childHas = getChildrenHaveSubmit(children);
            if (childHas) {
                return true;
            }
        }
    }
    return false;
}
// Check the children for an h3 with form__title
export const getChildrenHaveTitle = (children) => {
    let childHas = false;
    for (const child of React.Children.toArray(children)) {
        const children = getChildren(child);
        if (isTitle(child)) {
            return true;
        } else if (children) {
            childHas = getChildrenHaveTitle(children);
            if (childHas) {
                return true;
            }
        }
    }
    return false;
}
export const fieldValuesDefault = (fieldValues) => {
    let count = 0;
    Object.entries(fieldValues).forEach(([key, value]) => {
        if (value && String(value).length) {
            count++;
        }
    });
    return count === 0;
}