const global = {
    formSuccess: null,
    successMessage: null,
    formFailure: null,
    failureMessage: null,
    canUpdateCustomer: true,
    setCanUpdateCustomer(value) {
        this.canUpdateCustomer = value;
    },
    initialScrollTo(identifier) {
        return this.formSuccess === identifier || this.formFailure === identifier;
    },
    reset() {
        global.formSuccess = null;
        global.successMessage = null;
        global.formFailure = null;
        global.failureMessage = null;
    }
}

export default global;