import { forwardRef } from 'react';
import { getClassName } from 'helper/bem';
import Loading from './Loading';

const LoadingPanel = forwardRef((props, ref) => {
    return (
        <div className={ getClassName('loading-panel', props.className) }>
            <div className="loading-panel__inner">
                <Loading />
            </div>
        </div>
    )
});

export default LoadingPanel;