import PropTypes from 'prop-types';
import MockDataElement from 'components/ui/MockDataElement';

const MockData = (props) => {
    const rows = new Array(props.number);
    rows.fill(null);
    return (
        <>
            { rows.map((value, index) => {
                let style = {};
                if (props.style) {
                    style = props.style;
                }
                if (props.randomise) {
                    style.width = Math.floor(Math.random() * 100);
                    if (props.minWidth && style.width < props.minWidth) {
                        style.width = props.minWidth;
                    }
                    style.width = style.width + '%'
                }
                return <MockDataElement key={ index } { ...props } style={ style }/>;
            }) }
        </>
    );
}

MockData.defaultProps = {
    number: 1,
}
MockData.propTypes = {
    number: PropTypes.number,
    minWidth: PropTypes.number,
    randomise: PropTypes.bool,
    style: PropTypes.object,
}

export default MockData;