export const getNewValue = (options, value, eventCode) => {
    let keys = Object.keys(options);
    let index = keys.indexOf(value);
    let newValue = '';
    if (eventCode === 'ArrowDown') {
        if ((index + 2) > keys.length) {
            newValue = '';
        } else {
            newValue = keys[index + 1];
        }
    } else if (eventCode === 'ArrowUp') {
        if (index === -1) {
            newValue = keys[keys.length - 1];
        } else if (index === 0) {
            newValue = '';
        } else {
            newValue = keys[index - 1];
        }
    }
    return newValue;
}

export const getNextIndex = (eventCode, activeIndex, items) => {
    let index = activeIndex;
    if (items.length === 1) {
        index =  0;
    } else {
        if (eventCode === 'ArrowDown') {
            if (index === null) {
                index = 0;
            } else {
                index ++;
            }
        } else if (eventCode === 'ArrowUp') {
            if (index === null) {
                index = (items.length - 1);;
            } else {
                index --;
            }
        }
        if (index > (items.length - 1)) {
            index = 0;
        } else if (index < 0) {
            index = (items.length - 1);
        }
    }
    return index;
}