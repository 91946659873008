const minLength = 12;

export const validationItems = {
    hasLength: {
        message: 'must contain at least ' + minLength + ' characters',
        colour: 'danger',
        validator: (value) => (value.length >= minLength)
    },
    hasLetter: {
        message: 'must contain at least one letter a-z',
        colour: 'warning',
        validator: (value) => /[a-z]/i.test(value)
    },
    hasUppercase: {
        message: 'must contain at least one uppercase letter A-Z',
        colour: 'warning',
        validator: (value) => /[A-Z]/.test(value)
    },
    hasNumber: {
        message: 'must contain at least one number',
        colour: 'warning',
        validator: (value) => /\d/.test(value)
    },
    hasSymbol: {
        message: 'must contain at least one symbol (@$!%*#?&£-)',
        colour: 'warning',
        validator: (value) => /[@$!%*#?&£-]/.test(value)
    }
};

export const getPasswordValidation = (fieldLabel, fieldValue, email = null) => {
    if (email) {
        email = email.split('@')[0].toLowerCase();
    }
    fieldValue = String(fieldValue);

    for (let validationItemKey in validationItems) {
        let validationItem = validationItems[validationItemKey];
        if (!validationItem.validator(fieldValue)) {
            return {
                valid: false,
                colour: validationItem.colour,
                message: fieldLabel + ' ' + validationItem.message
            }
        }
    }

    if (email && fieldValue.includes(email)) {
        return {
            valid: false,
            colour: 'warning',
            message: fieldLabel + ' cannot contain the first part of your email address'
        }
    }

    return { valid: true, message: '', colour: '' };
}

export default getPasswordValidation;