const debug = {
    info(message, data) {
        this.show('info', message, data);
    },
    log(message, data) {
        this.show('log', message, data);
    },
    warning(message, data) {
        this.show('warning', message, data);
    },
    table(message, data) {
        this.show('table', message, data);
    },
    dir(message, data) {
        this.show('dir', message, data);
    },
    error(message, data) {
        this.show('error', message, data);
    },
    show(type, message, data) {
        if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_SHOW_DEBUG === 'true') {
            if (!!window.MSInputMethodContext && !!document.documentMode) {
                type = 'log';
            }
            if (data) {
                console[type](message, data);
            } else {
                console[type](message);
            }
        }
    },
    showPerformanceMetrics(response) {
        let metrics = response.data.performance
        const requestTime = response.headers['request-duration'] / 1000;
        let totalTime = metrics.reduce((partialSum, a) => {
            return partialSum + a.time;
        }, 0);
        metrics = response.data.performance.map(metric => {
            return {
                "Method": metric.method,
                "API URL": metric.url,
                "Time (seconds)": (Math.round(metric.time * 100) / 100),
                "Cached?": metric.cached
            };
        });
        totalTime = (Math.round(totalTime * 100) / 100);
        const apiTimePercentage = Math.round((totalTime / requestTime) * 10000) / 100;
        metrics.push({}); // console.table doesn't show the last key in an array
        this.show('info', 'Request: ' + response.config.url + ' total time ' + requestTime + ' seconds / API time ' + totalTime + ' seconds (' + apiTimePercentage + '% for API)');
        this.show('table', metrics);
    }
}

export default debug;