import Text from 'components/form/field/Text';

const Currency = (props) => {
    const keyUp = (event) => {
        let value = event.target.value.toString().replace(/[^0-9.-]+/g, '');
        let minusPosition;
        let periodPosition;

        // Remove minuses except first character
        while ((minusPosition = value.lastIndexOf('-')) > 0) {
            value = value.slice(0, minusPosition) + value.slice(minusPosition + 1);
        }
        // Remove periods except first occurrence
        while ((periodPosition = value.lastIndexOf('.')) > value.indexOf('.')) {
            value = value.slice(0, periodPosition) + value.slice(periodPosition + 1);
        }

        if (isNaN(value) && value !== '-') {
            value = '';
        } else {
            const values = value.split('.');
            if (values[1] && values[1].length > 2) {
                values[1] = values[1].substr(0, 2);
            }
            value = values.join('.');
        }
        if (value !== event.target.value) {
            props.setFieldValues({...props.fieldValues, [props.name]: value});
        }
    }
    return <>
        <span className="form__field-before">£</span>
        <Text { ...props } onKeyUp={ keyUp } onBlur={ keyUp } allowedAttributes={ ['onKeyUp'] } />
    </>
};

export default Currency;