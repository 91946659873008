import React from 'react';
import { getAllowedAttributes } from 'helper/form/fields';

const Hidden = (props) => {
    const allowedAttributes = [
        'name',
        'id',
        'value',
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    return (
        <input type="hidden" { ...attributes } onChange={ props.inputChangeHandler } onFocus={ props.inputChangeHandler }/>
    );
};

export default Hidden;