import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/ui/Icon';
import { Link } from 'react-router-dom';
import Loading from 'components/ui/Loading';

import { getClassName } from 'helper/bem';
import url from 'helper/url';
import { api } from 'helper/api/client';
import permission from 'helper/permission';

const Button = forwardRef((props, ref) => {
    const [loading, setLoading] = useState(false);
    let Element = Link;
    if (props.disabled || (!props.to && !props.href)) {
        Element = 'button';
    }
    if (!props.disabled && url.isFull(props.href)) {
        Element = 'a';
    }
    let classNames = [];
    classNames.push((props.simple ? 'simple' : 'full'));
    if (props.colour) {
        classNames.push(props.colour);
    } else {
        classNames.push('purple');
    }
    if (props.outline) {
        classNames.push('outline');
    }
    if (!props.visible) {
        classNames.push('hidden');
    }
    if (props.iconOnly) {
        classNames.push('icon');
    }
    if (props.noPadding) {
        classNames.push('no-padding');
    }
    if (loading || props.loading === true) {
        classNames.push('loading');
    }
    if (props.size) {
        classNames.push(props.size);
    }
    if (props.className) {
        classNames.push(props.className);
    }
    let loadingSize = 20;
    if (props.size === 'small') {
        loadingSize = 16;
    }
    const onClick = (event) => {
        event.stopPropagation();
        if (props.onClick) {
            props.onClick(event, props);
        }
        if (props.type !== 'submit' && !props.to && !props.href) {
            event.preventDefault();
        }
        if (!loading) {
            if (props.modal) {
                props.dispatchModal({
                    type: 'open',
                    buttonEvent: event,
                    title: props.modalTitle,
                    content: props.modalContent,
                    apiAction: props.apiAction,
                    apiData: props.apiData,
                    apiVerb: props.apiVerb,
                    apiSuccess: props.apiSuccess,
                    yesOption: props.modalYesOption,
                    noOption: props.modalNoOption,
                });
            } else if (props.apiAction) {
                setLoading(true);
                let apiVerb = (props.apiVerb ? props.apiVerb : 'get');
                api[apiVerb](props.apiAction, props.apiData).then(response => {
                    setLoading(false);
                    if (props.apiSuccess) {
                        props.apiSuccess(response, props.apiData, event);
                    }
                    if (apiVerb === 'get' && props.apiCache !== null) {
                        api.setCache(response, props.apiCache);
                    }
                }).catch(error => {
                    setLoading(false);
                    if (props.apiFailure) {
                        props.apiFailure(props.apiData, event, error);
                    }
                    api.error(error);
                });
            } else if (props.action) {
                props.action(event);
                if (props.actionFinished) {
                    props.actionFinished(event);
                }
            }
        }
    }
    const getDataAttributes = () => {
        let dataAttributes = {};
        Object.entries(props).forEach(([key, value]) => {
            if (key.substring(0, 5) === 'data-') {
                dataAttributes[key] = value;
            }
        });
        return dataAttributes;
    }
    // If loading remove any Icon from children
    const getChildren = (children) => {
        let gotChildren = React.Children.toArray(children).map(child => {
            if ((props.loading || loading) && (child.type && child.type.name === 'Icon')) {
                return null;
            }
            // Return the original child unchanged
            return child;
        });
        if (props.to && !permission.validRoute(props.to) && (process.env.REACT_APP_NAV_TEST_MODE || process.env.REACT_APP_NAV_TEST_MODE === 'true')) {
            gotChildren.push(' *');
        }
        return gotChildren;
    }
    const getButtonInner = () => {
        if (props.fullClassName) {
            return getChildren(props.children)
        }
        if (!props.iconOnly) {
            return <span className="button__inner">{ getChildren(props.children) }</span>
        }
    }
    if (props.to && !permission.validRoute(props.to)) {
        if (!process.env.REACT_APP_NAV_TEST_MODE || process.env.REACT_APP_NAV_TEST_MODE === 'false') {
            return null;
        }
    }

    return (
        <Element ref={ ref } type={ (props.to || props.href ? null : props.type) } title={ props.title } disabled={ props.disabled } className={ (props.fullClassName ? props.fullClassName : getClassName('button', classNames)) }
                 onClick={ onClick } { ...getDataAttributes() } to={ (props.to ? props.to : props.href) } href={ Element === 'a' ? props.href : null } target={ Element === 'a' ? 'portalExternal' : null }>
            { (loading || props.loading) && <Loading className="button__loading" size={ loadingSize }/> }
            { (props.icon && !props.loading && !loading) && <Icon icon={ props.icon } className="button__icon"/> }
            { getButtonInner() }
        </Element>
    );
});

Button.defaultProps = {
    simple: false, // Underline only
    colour: 'blue',
    visible: true,
    disabled: false,
    outline: false,
    type: 'button',
    title: '', // HTML title attribute
    fullClassName: '', // To allow a full class to be specified
    size: '', // empty or small
    apiCache: null, // How long to cache API (GET only) requests for
    apiAction: null, // API action path only (exclude URL)
    apiData: {}, // Pass a custom data object to the API
    apiSuccess: null, // function after success. Returns (response, apiData, event)
    apiFailure: null, // function after failure. Returns (apiData, event)
    action: null, // custom JS callback for onClick
    success: null, // custom JS success (after loading)
    failure: null, // custom JS failure (after loading)
    iconOnly: false, // Removes padding to the right of icon so it's more square
    loading: false, // To set the loading state
};

Button.propTypes = {
    simple: PropTypes.bool,
    colour: PropTypes.string,
    visible: PropTypes.bool,
    outline: PropTypes.bool,
    title: PropTypes.string,
    fullClassName: PropTypes.string,
    size: PropTypes.oneOf(['', 'small']),
    apiAction: PropTypes.string,
    apiCache: PropTypes.number,
    apiData: PropTypes.object,
    apiSuccess: PropTypes.func,
    apiFailure: PropTypes.func,
    action: PropTypes.func,
    success: PropTypes.func,
    failure: PropTypes.func,
    iconOnly: PropTypes.bool,
    loading: PropTypes.bool,
}

export default Button;