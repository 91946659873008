const modalInitialState = {
    open: false,
    closeIn: null,
    title: null,
    content: null,
    yesOption: null,
    yesAction: () => {
    },
    noOption: null,
    apiAction: null,
    apiData: {},
    apiVerb: null,
    apiSuccess: null,
    changeWarning: false,
}
const modalReducer = (state, action) => {
    switch (action.type) {
        case 'open': {
            return {
                ...state,
                // These are before the action so they get defaulted and hidden unless sent by the action
                yesOption: null,
                noOption: null,
                ...action,
                closeIn: null,
                changeWarning: false,
                open: true,
            };
        }
        case 'close': {
            return {
                ...state,
                changeWarning: false,
                open: false,
            }
        }
        case 'closeIn': {
            return { ...state, closeIn: action.time };
        }
        case 'title': {
            return { ...state, title: action.title };
        }
        case 'content': {
            return { ...state, content: action.content };
        }
        case 'yesOption': {
            return { ...state, yesOption: action.yesOption };
        }
        case 'yesAction': {
            return { ...state, yesOption: action.yesOption };
        }
        case 'noOption': {
            return { ...state, noOption: action.noOption };
        }
        case 'changeWarning': {
            return { ...state, changeWarning: true };
        }

        default:
            throw new Error(`Not supported action ${ action.type }`);
    }
}

export { modalReducer, modalInitialState };

/*
EXAMPLE:

import { modalReducer } from 'helper/modalReducer';

...

const [modal, dispatchModal] = useReducer(modalReducer, {});

// Set open
dispatchModal({ type: 'open', title: <title>, content: <content> })
// Set close
dispatchModal({ type: 'close' })
// Set title
dispatchModal({ type: 'title', title: <title> })
// Set content
dispatchModal({ type: 'content', content: <content> })
 */