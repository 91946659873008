import React from 'react';
import { getAllowedAttributes } from 'helper/form/fields';

const Radios = (props) => {
    const allowedAttributes = [
        'name',
        'value',
        'disabled',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    let className = 'form__field-radios';
    if (props.inline) {
        className += ' form__field-radios--inline';
    }
    return (
        <div className={ className }>
            {
                Object.entries(props.options).map(([value, title]) => {
                return (
                    <div className="form__field-radio" key={ value }>
                        <input type="radio" id={ props.name + '-' + value } { ...attributes } checked={ props.value === value } onChange={ props.inputChangeHandler } onFocus={ props.inputChangeHandler }
                               value={ value }/>
                        <label htmlFor={ props.name + '-' + value }>{ title }</label>
                    </div>
                );
            }) }
        </div>
    );
};

export default Radios;