import { useLocation } from 'react-router-dom';
import Hero from 'components/partial/Hero';
import Box from 'components/ui/Box';

const LoadingView = (props) => {
    const title = (props.title ? props.title : 'Loading...');
    const location = useLocation();
    const locationArray = location.pathname.split('/');
    return (
        <>
            { locationArray.length === 1 && <Hero title={ title }/> }
            <Box loading={ true } className={ props.className } />
        </>
    );
};

export default LoadingView;