import React, { useEffect, useRef, useState } from 'react';
import 'element-closest-polyfill';
import { createPortal } from 'react-dom';

import Icon from 'components/ui/Icon';
import Button from 'components/ui/Button';
import global from 'helper/global';
import NotificationMessage from '../components/ui/NotificationMessage';

const Modal = (props) => {
    const [actionRunning, setActionRunning] = useState(false);
    const [mouseCurrentlyDown, setMouseCurrentlyDown] = useState(false);
    const yesButton = useRef(); // Use to trigger clicks on in it from key presses
    const adminId = window.localStorage.getItem('_adminId');
    const setClosed = (event) => {
        if (!actionRunning) {
            if ((!props.changeWarning && !mouseCurrentlyDown && event.target.classList.contains('modal-shroud')) || event.currentTarget.classList.contains('modal-content__close')) {
                if (props.closeAction) {
                    props.closeAction(event);
                } else {
                    props.dispatchModal({ type: 'close' });
                }
                global.setCanUpdateCustomer(true);
            }
        }
    }
    const setMouseDown = (event) => {
        if (props.open && event.target.closest('.modal-content')) {
            setMouseCurrentlyDown(true);
        }
    }
    const setMouseUp = (event) => {
        if (mouseCurrentlyDown) {
            setTimeout(() => {
                setMouseCurrentlyDown(false)
            }, 100);
        }
    }
    useEffect(() => {
        const setKeydown = (event) => {
            if (props.open) {
                if (event.code === 'Escape') {
                    props.dispatchModal({ type: 'close' });
                } else if (yesButton.current && ['Space', 'Enter', 'NumpadEnter', 'KeyY'].includes(event.code)) {
                    yesButton.current.click();
                }
            }
        }
        setActionRunning(false);
        document.addEventListener('keydown', setKeydown, false);
        return () => {
            document.removeEventListener('keydown', setKeydown, false);
        };
    }, [props]);
    const yesAction = (event) => {
        setActionRunning(true);
        if (props.yesAction) {
            props.yesAction(event);
        }
    }
    const yesFinished = (event) => {
        setActionRunning(false);
        if (props.yesFinished) {
            props.yesFinished(event);
        }
        props.dispatchModal({ type: 'close' });
    }
    const noAction = (event) => {
        if (!actionRunning) {
            if (props.noAction) {
                props.noAction(event);
            } else {
                props.dispatchModal({ type: 'close' });
            }
        }
    }
    const apiSuccess = (response, apiData, event) => {
        setActionRunning(false);
        if (props.apiSuccess) {
            props.apiSuccess(response, apiData, event);
        }
        props.dispatchModal({ type: 'close' });
    }
    const apiFailure = (apiData, event) => {
        setActionRunning(false);
        if (props.apiFailure) {
            props.apiFailure(apiData, event);
        }
        props.dispatchModal({ type: 'close' });
    }

    let className = 'modal' + (props.open ? ' modal--open' : '') + (props.modifier ? ' modal--' + props.modifier : '');
    useEffect(() => {
        const modalTimeout = setTimeout(() => {
            if (props.closeIn) {
                props.dispatchModal({ type: 'close' });
            }
        }, props.closeIn);
        return () => clearInterval(modalTimeout);
    }, [props]);
    const getModalFooter = () => {
        let yesDisabled = false;
        if (adminId && adminId !== '' && (props.title.includes('remove') || props.title.includes('delete'))) {
            yesDisabled = true;
        }
        return (
            <div className="modal-content__footer">
                { adminId && adminId !== '' && yesDisabled && <NotificationMessage type="warning">Delete actions are disabled when logged into a customer's account</NotificationMessage> }
                { props.noOption && <Button onClick={ noAction } disabled={ actionRunning } outline={ true } colour="blue">{ props.noOption }</Button> }
                <Button ref={ yesButton }
                        onClick={ yesAction }
                        colour="blue"
                        disabled={ yesDisabled }
                        actionFinished={ yesFinished }
                        apiAction={ props.apiAction }
                        apiData={ props.apiData }
                        apiVerb={ props.apiVerb }
                        apiSuccess={ apiSuccess }
                        apiFailure={ apiFailure }
                >{ props.yesOption }</Button>
            </div>
        );
    }
    if (props.open) {
        global.setCanUpdateCustomer(false);
    }

    return createPortal(
        <div className={ className.trim() }>
            <div className="modal-shroud" disabled={ actionRunning } onClick={ setClosed } onMouseDown={setMouseDown} onMouseUp={setMouseUp}>
                <div className={ 'modal-content' + (props.open ? ' modal-content--open' : '') }>
                    { props.allowClose && <button className="modal-content__close" disabled={ actionRunning } onClick={ setClosed } title="Close"><Icon icon="close"/></button> }
                    <div className="modal-content__title">{ props.title }</div>
                    <div className={ 'modal-content__body' + (props.yesOption && props.yesAction ? ' modal-content__body--has-footer' : '') }>{ props.open && props.children }</div>
                    { props.yesOption && props.yesAction && getModalFooter() }
                </div>
            </div>
        </div>
        , document.getElementById('modal-wrapper'));
}

Modal.defaultProps = {
    allowClose: true,
    changeWarning: false,
    dispatchModal: () => {}
}

export default Modal;