import React from 'react';
import { Link } from 'react-router-dom';
import permission from 'helper/permission';
import Balance from 'components/ui/Balance';

const HeaderBalance = () => {
    const getBalance = () => {
        if (permission.validRoute('/payments/transactions')) {
            return (
                <Link to="/payments/transactions" className="header__balance-link">
                    <span className="header-title">Balance</span>
                    <Balance/>
                </Link>
            )
        } else {
            return (
                <>
                    <span className="header-title">Balance</span>
                    <Balance/>
                </>
            )
        }
    }
    if (permission.check('canMakePayments')) {
        return (
            <div className="header__balance">
                { getBalance() }
            </div>
        )
    }
    return null;
}

export default HeaderBalance;