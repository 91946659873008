import React from 'react';
import Radios from 'components/form/field/Radios';

const YesNo = (props) => {
    const options = [
        { name: '1', title: 'Yes' },
        { name: '0', title: 'No' }
    ];
    return (
        <Radios { ...props } inline={ true } options={ options }/>
    );
};

export default YesNo;