import React, { useState } from 'react';
import Icon from 'components/ui/Icon';
import Text from 'components/form/field/Text';

const Password = (props) => {
    const [inputType, setInputType] = useState('password');
    const [capsLock, setCapsLock] = useState(false);
    const setVisibility = (event) => {
        // Check that it's the icon that's clicked
        event.preventDefault();
        if (!props.disabled) {
            setInputType((inputType === 'password' ? 'text' : 'password'));
        }
    }
    const setBlur = (event) => {
        setCapsLock(false);
    }
    const setKeyDown = (event) => {
        setCapsLock(event.getModifierState('CapsLock'));
    }
    return <>
        <Text { ...props } autoComplete="off" inputType={ inputType } allowedAttributes={ ['onKeyDown', 'onBlur', ...props.allowedAttributes] } onKeyDown={ setKeyDown } onBlur={ setBlur } />
        { capsLock && <Icon icon="CapsLock" className="form__field-password-caps-lock" title="Caps lock is on" /> }
        { props.value.length > 0 && <button className="form__field-password-toggle" type="button" tabIndex="-1" onClick={ setVisibility }><Icon icon={ inputType === 'password' ? 'Eye' : 'EyeSlash' }/></button> }
    </>
};

export default Password;