import React from 'react';
import Text from 'components/form/field/Text';

const Percentage = (props) => {
    return <>
        <Text { ...props } inputType="number" allowedAttributes={ ['min', 'max'] } step={ 1 }/>
        <span className="form__field-after">%</span>
    </>
};

export default Percentage;