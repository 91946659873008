const format = {
    currency(value, decimals = 2, commaSeparated = true) {
        return (value < 0 ? '-' : '') + '£' + this.number(Math.abs(value), decimals, commaSeparated);
    },
    percentage(value, decimals = 2) {
        return this.number(value, decimals, false) + '%';
    },
    number(number, decimals = 2, commaSeparated = true) {
        if (number == null || !isFinite(number)) {
            throw new TypeError('number is not valid');
        }
        if (!decimals) {
            let len = number.toString().split('.').length;
            decimals = len > 1 ? len : 0;
        }
        number = parseFloat(number).toFixed(decimals);

        var split_number = number.split('.');
        if (commaSeparated) {
            split_number[0] = split_number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        if (typeof split_number[1] == 'undefined') {
            split_number[1] = '00';
        } else if (split_number[1].length < decimals) {
            for (let count = 0; count < decimals; count++) {
                split_number[1] += '0';
            }
        }
        number = split_number.join('.');
        return number;
    },
}
export default format;