import React from 'react';
import PropTypes from 'prop-types';

import log from 'helper/log';

// Note new icons must be added as an import AND to the icons object

// There's no dynamic way to do this without using XHR which adds some visual delay
import { ReactComponent as Account } from 'images/icon/account.svg';
import { ReactComponent as AdminSwitch } from 'images/icon/admin-switch.svg';
import { ReactComponent as AdminSwitchBack } from 'images/icon/admin-switch-back.svg';
import { ReactComponent as ArrowDown } from 'images/icon/arrow-down.svg';
import { ReactComponent as ArrowLarge } from 'images/icon/arrow-large.svg';
import { ReactComponent as ArrowLeft } from 'images/icon/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'images/icon/arrow-right.svg';
import { ReactComponent as ArrowUp } from 'images/icon/arrow-up.svg';
import { ReactComponent as Bulk } from 'images/icon/bulk.svg';
import { ReactComponent as Basket } from 'images/icon/basket.svg';
import { ReactComponent as CapsLock } from 'images/icon/caps-lock.svg';
import { ReactComponent as Check } from 'images/icon/check.svg';
import { ReactComponent as Close } from 'images/icon/close.svg';
import { ReactComponent as Cookie } from 'images/icon/cookie.svg';
import { ReactComponent as CookieIcon } from 'images/icon/cookie-icon.svg';
import { ReactComponent as Cross } from 'images/icon/cross.svg';
import { ReactComponent as CylinderCharge } from 'images/icon/cylinder-charge.svg';
import { ReactComponent as CylinderReturn } from 'images/icon/cylinder-return.svg';
import { ReactComponent as Delete } from 'images/icon/delete.svg';
import { ReactComponent as Download } from 'images/icon/download.svg';
import { ReactComponent as DoubleArrow } from 'images/icon/double-arrow.svg';
import { ReactComponent as External } from 'images/icon/external.svg';
import { ReactComponent as Favourite } from 'images/icon/favourite.svg';
import { ReactComponent as FavouriteNot } from 'images/icon/favourite-not.svg';
import { ReactComponent as Eye } from 'images/icon/eye.svg';
import { ReactComponent as EyeSlash } from 'images/icon/eye-slash.svg';
import { ReactComponent as GasBottle } from 'images/icon/gas-bottle.svg';
import { ReactComponent as GaugeReading } from 'images/icon/gauge-reading.svg';
import { ReactComponent as Info } from 'images/icon/info.svg';
import { ReactComponent as Installation } from 'images/icon/installation.svg';
import { ReactComponent as LiveChat } from 'images/icon/live-chat.svg';
import { ReactComponent as MovingOut } from 'images/icon/moving-out.svg';
import { ReactComponent as Minus } from 'images/icon/minus.svg';
import { ReactComponent as PaginationEnd } from 'images/icon/pagination-end.svg';
import { ReactComponent as PaginationLeft } from 'images/icon/pagination-left.svg';
import { ReactComponent as PaginationRight } from 'images/icon/pagination-right.svg';
import { ReactComponent as PaginationStart } from 'images/icon/pagination-start.svg';
import { ReactComponent as Payments } from 'images/icon/payments.svg';
import { ReactComponent as Placeholder } from 'images/icon/placeholder.svg';
import { ReactComponent as Plus } from 'images/icon/plus.svg';
import { ReactComponent as Online } from 'images/icon/online.svg';
import { ReactComponent as Overview } from 'images/icon/overview.svg';
import { ReactComponent as Print } from 'images/icon/print.svg';
import { ReactComponent as Returns } from 'images/icon/returns.svg';
import { ReactComponent as Search } from 'images/icon/search.svg';
import { ReactComponent as SignOut } from 'images/icon/sign-out.svg';
import { ReactComponent as SortUp } from 'images/icon/sort-up.svg';
import { ReactComponent as SortDown } from 'images/icon/sort-down.svg';
import { ReactComponent as Stockists } from 'images/icon/stockists.svg';
import { ReactComponent as Success } from 'images/icon/success.svg';
import { ReactComponent as Telemetry } from 'images/icon/usage.svg';
import { ReactComponent as Usage } from 'images/icon/usage.svg';
import { ReactComponent as Warning } from 'images/icon/warning.svg';

const Orders = () => {
    if (log.customer.sector === 'bulk') {
        return Bulk;
    } else {
        return GasBottle;
    }
}

const icons = {
    Account,
    AdminSwitch,
    AdminSwitchBack,
    ArrowDown,
    ArrowLarge,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    Bulk,
    Basket,
    CapsLock,
    Check,
    Close,
    Cookie,
    Cross,
    CylinderCharge,
    CylinderReturn,
    Delete,
    Download,
    DoubleArrow,
    External,
    Eye,
    EyeSlash,
    Favourite,
    FavouriteNot,
    GasBottle,
    GaugeReading,
    Info,
    Installation,
    LiveChat,
    Minus,
    MovingOut,
    Online,
    Overview,
    Orders,
    PaginationEnd,
    PaginationLeft,
    PaginationRight,
    PaginationStart,
    Payments,
    Placeholder,
    Plus,
    Print,
    Returns,
    Search,
    SignOut,
    SortUp,
    SortDown,
    Stockists,
    Success,
    Telemetry,
    Usage,
    Warning,
    CookieIcon,
}


const Icon = (props) => {
    let IconName = (icons[props.icon] ? icons[props.icon] : icons.Placeholder);
    if (props.icon === 'Orders') {
        if (log.customer.type === 'bulk') {
            IconName = icons.Bulk;
        } else {
            IconName = icons.GasBottle;
        }
    }

    if (props.className) {
        return <span className={ props.className }><IconName /></span>;
    }
    return <IconName />;
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
}

export default Icon;