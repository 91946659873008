import React, { useState, useEffect } from 'react';
import Password from 'components/form/field/Password';
import PasswordStrengthMessage from '../../ui/PasswordStrengthMessage';

const ConfirmPassword = (props) => {
    const [doesMatch, setDoesMatch] = useState(false);
    const passwordField = props.formFields[props.name].passwordField;
    const password = String(props.value);
    useEffect(() => {
        setDoesMatch(password.length > 0 && password === props.fieldValues[passwordField]);
    }, [password, props, passwordField]);
    return <>
        <Password { ...props } />
        <ul className="form__field-password-strength">
            {
                doesMatch
                    ? <PasswordStrengthMessage valid={doesMatch} message="Passwords match" />
                    : <PasswordStrengthMessage valid={doesMatch} message="Passwords do not match" useInvalid={ password.length > 0} />
            }
        </ul>
    </>
};

export default ConfirmPassword;