import React, { useState, useEffect, useRef } from 'react';
import Text from 'components/form/field/Text';
import FieldSet from 'components/form/FieldSet';
import { api, axios } from 'helper/api/client';
import Button from 'components/ui/Button';
import { getClassName } from 'helper/bem';
import Loading from 'components/ui/Loading';

let cancelToken;

const AddressLookup = (props) => {
    const hasAddressFieldsVisible = () => {
        let fieldsVisible = addressFieldsVisible;
        if (!fieldsVisible) {
            for (const targetField of props.targetFields) {
                if (!props.fieldValidation[targetField].valid) {
                    fieldsVisible = true;
                }
            }
        }
        return fieldsVisible;
    }
    const listElement = useRef(null);
    const findButton = useRef(null);
    const [loading, setLoading] = useState(false);
    const [addressListloading, setAddressListloading] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [addressBlock, setAddressBlock] = useState(null);
    const [addressesListOpen, setAddressesListOpen] = useState(false);
    const [addressFieldsVisible, setAddressFieldsVisible] = useState((props.addressFieldsVisible ? props.addressFieldsVisible : false));
    const [selectedAddress, setSelectedAddress] = useState(null);
    const addressBlockClick = (event) => {
        event.preventDefault();
        setAddresses([]);
        setAddressBlock(addresses[event.target.dataset.key]);
        setAddressListloading(true);
        setLoading(true);
    }
    const setAddressInputClick = (event) => {
        if (addresses.length) {
            setAddressesListOpen(true);
        }
    }
    const setAddressList = () => {
        if (props.fieldValues[props.name]) {
            const addressLookup = props.fieldValues[props.name].trim();
            if (addressLookup.length > 1) {
                if (typeof cancelToken !== 'undefined') {
                    cancelToken.cancel();
                }
                setLoading(true);
                cancelToken = axios.CancelToken.source();
                api.get('address/search', { search: addressLookup }, { cancelToken: cancelToken.token, }).then(response => {
                    setLoading(false);
                    if (api.success(response)) {
                        setAddresses(response.data.response.addressBlocks);
                        setAddressesListOpen(true);
                        api.setCache(response);
                    } else {
                        setAddresses([]);
                    }
                }).catch(error => {
                    api.error(error);
                });
            }
        } else {
            setAddressesListOpen(false);
            setAddresses([]);
        }
    }
    useEffect(() => {
        if (addressBlock) {
            cancelToken = axios.CancelToken.source();
            let params = { id: addressBlock.id };
            if (addressBlock.type !== 'Address') {
                params = { search: addressBlock.text, container: addressBlock.id }
            }
            api.get('address' + (addressBlock.type === 'Address' ? '' : '/search'), params, { cancelToken: cancelToken.token, }).then(response => {
                if (addressBlock.type === 'Address') {
                    setAddressesListOpen(false);

                    const addressValues = Object.values(response.data.response.address);
                    let addressFormObject = {
                        [props.name]: ''
                    };
                    for (let index = 0; index < 5; index++) {
                        addressFormObject[props.targetFields[index]] = addressValues[index];
                    }
                    props.setFieldValues({ ...props.fieldValues, ...addressFormObject });
                    setAddressFieldsVisible(true);

                } else {
                    setAddresses(response.data.response.addressBlocks);
                    setAddressesListOpen(true);
                }
                setLoading(false);
                setAddressListloading(false);
                api.setCache(response);
            }).catch(error => {
                api.error(error);
            });

        }
        // eslint-disable-next-line
    }, [addressBlock]);
    useEffect(() => {
        const setKeydown = (event) => {
            if (addresses.length) {
                if (['Enter', 'NumpadEnter'].includes(event.code)) {
                    if (selectedAddress !== null) {
                        setLoading(true);
                        setAddressListloading(true);
                        setAddresses([]);
                        setAddressBlock(addresses[selectedAddress]);
                    } else {
                        event.preventDefault();
                    }
                } else if (event.code === 'Escape') {
                    setAddresses([]);
                } else if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
                    let newIndex;
                    if (selectedAddress !== null) {
                        newIndex = selectedAddress;
                        if (event.code === 'ArrowDown') {
                            newIndex++;
                            if (newIndex >= addresses.length) {
                                newIndex = 0;
                            }
                        } else if (event.code === 'ArrowUp') {
                            newIndex--;
                            if (newIndex < 0) {
                                newIndex = addresses.length - 1;
                            }
                        }
                    } else {
                        if (event.code === 'ArrowDown') {
                            newIndex = 0;
                        } else if (event.code === 'ArrowUp') {
                            newIndex = addresses.length - 1;
                        }
                    }
                    setSelectedAddress(newIndex);
                    // Focus on the button so if enter is pressed it'll select the address
                    listElement.current.children[newIndex].children[0].focus();
                }
            } else if (['Enter', 'NumpadEnter'].includes(event.code)) {
                event.preventDefault();
                if (findButton.current) {
                    findButton.current.click();
                }
            }
        }
        document.addEventListener('keydown', setKeydown, false);
        return () => {
            document.removeEventListener('keydown', setKeydown, false);
        };
        // eslint-disable-next-line
    }, [addresses, setAddresses, selectedAddress]);
    const fieldsetProps = {
        formFields: props.formFields,
        fieldValues: props.fieldValues,
        fieldsDisabled: props.fieldsDisabled,
        fieldVisibility: props.fieldVisibility,
        fieldValidation: props.fieldValidation,
        setFieldValues: props.setFieldValues,
        inputChangeHandler: props.inputChangeHandler,
        showSubmit: props.showSubmit,
        getSubmit: props.getSubmit
    }
    return (
        <div className="form__field-address-lookup-wrapper">
            <Text { ...props } disabled={ loading } autoComplete="off" onClick={ setAddressInputClick } allowedAttributes={ ['onClick'] }/>
            <Button ref={ findButton } fullClassName="form__field-address-lookup-wrapper-search" simple onClick={ setAddressList } loading={ loading }>Find address</Button>
            { !hasAddressFieldsVisible() && <Button simple noPadding onClick={ () => setAddressFieldsVisible(true) }>Enter address manually</Button> }
            <ul ref={ listElement } className={ getClassName('form__field-address-lookup-list', (addressesListOpen ? 'visible' : '') + (addressListloading ? ' loading' : '')) }>
                { addressListloading &&
                    <li className="form__field-address-lookup-list-item--loading">
                        <Loading className="form__field-address-lookup-list-item__loading" size="40"/>
                        <span>Loading address{ addressBlock && addressBlock.type === 'Address' ? '' : 'es' }...</span>
                    </li>
                }
                { addresses.map((address, index) => {
                    return <li key={ index } className={ 'form__field-address-lookup-list-item' + (index === selectedAddress ? ' form__field-address-lookup-list-item--selected' : '') }>
                        <button data-key={ index } type="button" onClick={ addressBlockClick }>{ (address.type === 'Address' ? address.text : address.description) }</button>
                    </li>
                }) }
            </ul>
            <div className={ 'form__field-address-lookup-fields' + (hasAddressFieldsVisible() ? '' : ' form__field-address-lookup-fields--hidden') }>
                { props.allFieldsFullWidth
                    ? <FieldSet fields={ props.targetFields } { ...fieldsetProps } />
                    : <>
                        <FieldSet fields={ [props.targetFields[0], props.targetFields[1]] } { ...fieldsetProps } />
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ [props.targetFields[2]] } { ...fieldsetProps } />
                            </div>
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ [props.targetFields[3]] } { ...fieldsetProps } />
                            </div>
                        </div>
                        <div className="form__fields-row">
                            <div className="form__fields-row-field form__fields-row-field--col-6">
                                <FieldSet fields={ [props.targetFields[4]] } { ...fieldsetProps } />
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

AddressLookup.defaultProps = {
    targetFields: ['address1', 'address2', 'town', 'county', 'postcode'],
    allFieldsFullWidth: false
}
// prop types
export default AddressLookup;