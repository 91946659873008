import React, { useRef, useEffect } from 'react';
import { getAllowedAttributes } from 'helper/form/fields';
import { useDetectOutsideClick } from 'helper/useDetectOutsideClick';
import { getNewValue } from 'helper/dropdown';

const Dropdown = (props) => {
    const allowedAttributes = [
        'name',
        'value',
        'disabled',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    const allTitle = (props.allTitle ? props.allTitle : 'All');
    const buttonRef = useRef(null);
    const menuRef = useRef(null);
    const valueRef = useRef(null);
    const [open, setOpen] = useDetectOutsideClick(menuRef, false);
    const dropdownToggle = (event) => {
        event.preventDefault();
        setOpen(!open);
    }
    const getSelectedTitle = () => {
        if (props.value === '') {
            if (props.showAll) {
                return allTitle;
            } else {
                return props.options[Object.keys(props.options)[0]];
            }
        } else {
            return props.options[props.value];
        }
    }
    const selectOption = (event) => {
        event.preventDefault();
        const changeEvent = { target: { name: props.name, value: event.currentTarget.dataset.value } };
        props.inputChangeHandler(changeEvent);
        setOpen(false);
    }
    useEffect(() => {
        const setKeydown = (event) => {
            const dropdownFocused = buttonRef.current && document.activeElement === buttonRef.current;
            const upDown = dropdownFocused && ['ArrowDown', 'ArrowUp'].includes(event.code);
            if (!dropdownFocused && ['Enter', 'NumpadEnter'].includes(event.code)) {
                event.preventDefault();
            }
            if (upDown) {
                event.preventDefault();
            }
            if (open) {
                if (event.code === 'Escape') {
                    setOpen(false);
                } else if (upDown) {
                    let newValue = getNewValue(props.options, props.value, event.code);
                    const changeEvent = { target: { name: props.name, value: newValue } };
                    props.inputChangeHandler(changeEvent);
                }
            } else if (upDown) {
                setOpen(true);
            }
        }
        document.addEventListener('keydown', setKeydown, false);
        return () => {
            document.removeEventListener('keydown', setKeydown, false);
        };
    }, [open, setOpen, props]);
    let className = 'form__field-control form__field-control--select' + (open ? ' form__field-control--select-open' : '');
    return (
        <div className="form__field-select-wrapper">
            <button ref={ buttonRef } type="button" className={ className } { ...attributes } onClick={ dropdownToggle }>{ getSelectedTitle() }</button>
            <ul ref={ menuRef } className={ 'form__field-select-options' + (open ? ' form__field-select-options--open' : '') }>
                { props.showAll &&
                <li className={ 'form__field-select-options-list-item form__field-select-options-list-item--all' + (props.value === '' ? ' form__field-select-options-list-item--selected' : '') }>
                    <button type="button" data-value="" tabIndex={ open ? '' : '-1' } onClick={ selectOption }>{ allTitle }</button>
                </li> }
                {
                    Object.entries(props.options).map(([value, title]) => {
                        return <li className={ 'form__field-select-options-list-item' + (props.value === value ? ' form__field-select-options-list-item--selected' : '') } key={ value }>
                            <button type="button" data-value={ value } tabIndex={ open ? '' : '-1' } onClick={ selectOption }>{ title }</button>
                        </li>
                    })
                }
            </ul>
            <input ref={ valueRef } type="hidden" { ...attributes }/>
        </div>
    );
};

Dropdown.defaultProps = {
    showAll: true
}

export default Dropdown;
