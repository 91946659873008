import Loading from 'components/ui/Loading';
import { getClassName } from 'helper/bem';
import PropTypes from 'prop-types';
import LoadingPanel from './LoadingPanel';

const Box = (props) => {
    let classNames = '';
    if (props.loading) {
        classNames += ' loading';
    }
    if (props.loadingOverlay) {
        classNames += ' loading-overlay';
    }
    if (props.padding) {
        classNames += ' padding-' + props.padding;
    }
    if (props.className) {
        classNames += ' ' + props.className;
    }
    return (
        <div className={ getClassName('box', classNames) + (props.noPrint ? ' no-print' : '') }>
            { props.loading && <Loading colour="purple" size="40"/> }
            { props.loadingOverlay && <LoadingPanel /> }
            {/*{ props.loading && <MockParagraphs rows={ 3 } /> }*/}
            { !props.loading && props.children }
        </div>
    );
}


Box.defaultProps = {
    padding: '',
    loading: false, // Will show a loading spinner in place of child content
    loadingOverlay: false, // Will show the shroud over the top of the child content
    noPrint: false,
};

Box.propTypes = {
    padding: PropTypes.oneOf(['', 'large', 'none']),
    noPrint: PropTypes.bool,
}

export default Box;