import React, { isValidElement } from 'react';
import Icon from 'components/ui/Icon';
import { useEffect, useState } from 'react';
import { getClassName } from 'helper/bem';
import global from 'helper/global';

let timeout;
const NotificationMessage = (props) => {
    const [visible, setVisible] = useState(true);
    const getMessage = () => {
        if (typeof props.children !== 'undefined') {
            return props.children;
        } else if (typeof props.message === 'string') {
            return <p dangerouslySetInnerHTML={ { __html:  props.message } } />;
        } else if (typeof props.message === 'object') {
            if (isValidElement(props.message)) {
                return props.message;
            } else {
                return props.message.map((messageLine, index) => <p key={ index }>{ messageLine }</p>);
            }
        }
        return '';
    }
    useEffect(() => {
        if (props.hideAfter) {
            timeout = setTimeout(() => {
                setVisible(false);
                if (props.globalFormSuccess) {
                    global.reset();
                }
            }, props.hideAfter * 1000);
            return () => clearTimeout(timeout);
        }
    }, [props.hideAfter, props.globalFormSuccess]);

    let className = getClassName('notification', [props.type, (props.className ? props.className : ''), (visible ? '' : 'hidden')]);

    const typeIconMap = {
        info: 'Info',
        success: 'Success',
        warning: 'Warning',
    }
    const message = getMessage();
    if (props.visible && props.button && message !== '') {
        return <div className={ className }><div className="notification__inner"><Icon icon={ typeIconMap[props.type] } className="notification__icon"/>{ message }{ props.button }</div></div>
    } else if (props.visible && message !== '') {
        return <div className={ className }><div className="notification__inner"><Icon icon={ typeIconMap[props.type] } className="notification__icon"/>{ message }</div></div>
    } else {
        return null;
    }
}
NotificationMessage.defaultProps = {
    type: 'info',
    time: 0,
    visible: true,
    hideAfter: null, // Hide notificaton after x seconds
    globalFormSuccess: null, // If it should reset the global form success after hideAfter
}

export default NotificationMessage;