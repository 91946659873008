import React, { useState, useEffect, useContext } from 'react';
import AuthContext from 'context/auth';
import MenuContext from 'context/menu';

import Routes from 'components/Routes';
import Header from 'components/ui/Header';
import Footer from 'components/ui/Footer';
import AdminBar from 'components/ui/AdminBar';
import UnAuthorisedHeader from 'components/ui/UnAuthorisedHeader';
import Nav from 'components/ui/Nav';
import GlobalNotificationMessage from 'components/ui/GlobalNotificationMessage';
import { useLocation, useHistory } from 'react-router-dom';
import loginImageDefault from 'images/login-default.jpg';
import loginImageDomestic from 'images/login-domestic.jpg';
import loginImageCommercial from 'images/login-commercial.jpg';
import loginImageAutoRegistration from 'images/login-autoregistration.jpg';
import { ReactComponent as Logo } from 'images/logo.svg';
import AutoLogout from './AutoLogout';
import CookieBanner from "./CookieBanner";

const BasicLayout = (props, loaded) => {
    return (
        <>
        <div className="app app--layout-basic">
            <div className="main">
                <UnAuthorisedHeader/>
                <GlobalNotificationMessage globalClass={ false }/>
                <div className="content">
                    <Routes/>
                </div>
                <Footer/>
            </div>
            <div className="login-image" style={ { backgroundImage: 'url(' + props.loginImage + ')' } } />
        </div>
        <CookieBanner />
        </>
    )
}
const FullLayout = (props) => {
    const logoClick = () => {
        props.menuContext.setMenuOpen(false);
        if (props.location.pathname !== '/overview') {
            props.history.push('/overview');
        }
    }
    return (
        <>
        <AutoLogout/>
        <div className="app app--layout-full">
            <button onClick={ logoClick } className="logo" ><Logo/></button>
            <Nav/>
            <div className="main">
                <Header/>
                <div className="content">
                    <Routes/>
                </div>
                <AdminBar/>
                <Footer/>
            </div>
        </div>
        </>
    )
}

const Layout = () => {
    const menuContext = useContext(MenuContext);
    const authContext = useContext(AuthContext);
    let location = useLocation();
    const history = useHistory();
    const [layout, setLayout] = useState('basic');
    const [loginImage, setLoginImage] = useState(loginImageDefault);
    useEffect(() => {
        const basicPaths = [
            '/', '/forgotten-password', '/reset-password', '/limited'
        ];
        setLayout(basicPaths.includes(location.pathname) || !authContext.authenticated ? 'basic' : 'full');
    }, [location.pathname, authContext.authenticated]);
    useEffect(() => {
        if (layout === 'basic') {
            switch (true) {
                case location.pathname === '/auto-register': setLoginImage(loginImageAutoRegistration); break;
                case window.localStorage.getItem('customerSector') === 'domestic': setLoginImage(loginImageDomestic); break;
                case window.localStorage.getItem('customerSector') === 'commercial': setLoginImage(loginImageCommercial); break;
                default: setLoginImage(loginImageDefault);
            }
        }
    }, [location.pathname, layout]);
    return (
        <>
        { layout === 'basic' && <BasicLayout loginImage={loginImage} /> }
        { layout === 'full' && <FullLayout menuContext={ menuContext } history={ history } location={ location } /> }
        </>
    )
}

export default Layout;