import debug from 'helper/debug';

const permission = {
    permissions: null,
    unauthenticatedRoutes: null,
    set(permissions) {
        this.permissions = {
            actions: {},
            routes: permissions.routes
        };
        for (const p in permissions.actions) {
            if (permissions.actions.hasOwnProperty(p)) {
                this.formatActionPermission(permissions.actions[p], p);
            }
        }
    },
    setUnauthenticatedRoutes(routes) {
        this.unauthenticatedRoutes = routes.filter(route => !route.requiresAuthentication).map(route => route.path);
    },
    formatActionPermission(item, key) {
        if (typeof item === 'object') {
            for (const p in item) {
                if (item.hasOwnProperty(p)) {
                    this.formatActionPermission(item[p], key + '.' + p);
                }
            }
        } else {
            this.permissions.actions[key] = item;
        }
    },
    check(identifier, defaultValue = false) {
        if (this.permissions && typeof this.permissions.actions[identifier] !== 'undefined') {
            return this.permissions.actions[identifier];
        } else {
            debug.error('Missing permission: ' + identifier);
            return defaultValue;
        }
    },
    validRoute(path) {
        let routes = this.permissions !== null ? this.permissions.routes : this.unauthenticatedRoutes;
        if (path.substr(0, 1) === '/') {
            path = path.substr(1);
        }
        path = path.split('?')[0];
        path = path.split('#')[0];
        if (path === '' || (path && path.substr(0, 4) === 'http') || routes.includes(path)) {
            return true;
        } else {
            const pathArray = path.split('/');
            if (pathArray.length > 1) {
                const foundRoute = routes.find(route => this.routeMatches(pathArray, route));
                if (foundRoute) {
                    return true;
                }
            }
        }
        return false;
    },
    routeMatches(pathArray, route) {
        if (route.includes(':')) {
            let matchCount = 0;
            const routePathArray = route.split('/');
            if (routePathArray.length === pathArray.length) {
                for (let pathCount = 0; pathCount < pathArray.length; pathCount++) {
                    if (routePathArray[pathCount].includes(':') || pathArray[pathCount] === routePathArray[pathCount]) {
                        matchCount ++;
                    }
                }
            }
            if (matchCount === routePathArray.length) {
                return true;
            }
        }
        return false;
    }
}
export default permission;