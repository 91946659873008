import React from 'react';
import { getAllowedAttributes } from 'helper/form/fields';

const Textarea = (props) => {
    const allowedAttributes = [
        'name',
        'id',
        'type',
        'disabled',
        'value',
        'placeholder',
        'rows',
        'minLength',
        'maxLength',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    return (
        <textarea className="form__field-control form__field-control--textarea" { ...attributes } onChange={ props.inputChangeHandler } onFocus={ props.inputChangeHandler }/>
    );
};

export default Textarea;