export const getCookieValue = (name) => {
    let cookies = document.cookie.split('; ').map((cookie) => cookie.split('=')),
        cookieValue = null;
    cookies.forEach((value) => {
        if (value[0] === 'cookie_options') {
            const cookie_options = JSON.parse(value[1]);
            cookieValue = cookie_options[name];
        }
    });
    return cookieValue;
};