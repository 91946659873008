import debug from 'helper/debug';

export const getFormFields = formFields => {
    for (const [fieldName, field] of Object.entries(formFields)) {
        field.name = fieldName;
        field.value = field.value || '';
        field.valid = true;
        field.validationMessage = '';
        if (!field.label && typeof field.showLabel === 'undefined') {
            debug.error('Form field label is missing', field);
        }
        if (!field.required) {
            field.required = false;
        }
        if (!field.minLength) {
            field.minLength = 0;
        }
        if (!field.maxLength && ['Text', 'Date', 'Email', 'Number', 'Password', 'PasswordStrength', 'ConfirmPassword', 'AddressLookup'].includes(field.type)) {
            field.maxLength = 255;
        }
        formFields[fieldName] = field;
    }
    return formFields;
};
export const getFieldValues = (formFields) => {
    let values = {};
    for (const field of Object.values(formFields)) {
        values[field.name] = String(field.value).trim();
    }
    return values;
};
export const getFieldLabels = (formFields) => {
    let labels = {};
    for (const field of Object.values(formFields)) {
        labels[field.name] = String(field.label).trim();
    }
    return labels;
};
export const getFieldVisibility = (formFields, fieldValues) => {
    let visibility = {};
    for (const field of Object.values(formFields)) {
        visibility[field.name] = (typeof field.visible !== 'undefined' ? field.visible : true);
        if (visibility[field.name] && field.visibleWhen) {
            Object.entries(field.visibleWhen).forEach(([visibleWhenField, visibleWhenValue]) => {
                if (typeof fieldValues[visibleWhenField] !== 'undefined' && fieldValues[visibleWhenField] !== visibleWhenValue) {
                    visibility[field.name] = false;
                }
            });
        }
    }
    return visibility;
};
export const getFieldsDisabled = (formFields, disabled = false) => {
    let fieldsDisabled = {};
    for (const field of Object.values(formFields)) {
        fieldsDisabled[field.name] = disabled || field.disabled;
    }
    return fieldsDisabled;
};
export const getRecordFields = (formFields, record) => {
    // Please be aware that this function does modify the original form object
    if (record) {
        for (const fieldName of Object.keys(formFields)) {
            if (typeof record[fieldName] !== 'undefined') {
                if (typeof record[fieldName] === 'boolean') {
                    record[fieldName] = (record[fieldName] ? '1' : '0');
                }
                formFields[fieldName].value = record[fieldName];
            }
        }
    } else {
        debug.error('Record undefined', formFields);
    }
    return formFields;
};
export const getAllowedAttributes = (attributes, allowedAttributes) => {
    allowedAttributes.push('inputType'); // Always allow this key to allow the field to specify a type attribute manually
    attributes = Object.keys(attributes)
        .filter(key => allowedAttributes.includes(key))
        .reduce((obj, key) => {
            obj[key] = attributes[key];
            if (key === 'type') {
                obj[key] = obj[key].toLowerCase();
            }
            return obj;
        }, {});
    if (attributes.inputType) {
        attributes.type = attributes.inputType; // Override with the inputType key rather than the same as the component name
        delete attributes.inputType; // Remove the invalid attribute
    }
    return attributes;
}