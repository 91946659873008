import { forwardRef } from 'react';

const Loading = forwardRef((props, ref) => {
    let className = (props.className ? props.className + ' ' : '') + 'loading';
    if (props.colour) {
        className += ' loading--' + props.colour;
    }
    return (
        <span ref={ ref } className={ className.trim() }>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0"
                 viewBox="0 0 64 64">
                <path opacity="0.25" d="M32,0v6c14.36,0,26,11.64,26,26h6C64,14.33,49.67,0,32,0z"/>
                <path d="M58,32c0,14.36-11.64,26-26,26S6,46.36,6,32C6,17.64,17.64,6,32,6V0C14.33,0,0,14.33,0,32s14.33,32,32,32
                    c17.67,0,32-14.33,32-32H58z"/>
            </svg>
        </span>
    )
});

export default Loading;