import 'scss/component/basket-message/basket-message.scss';
import format from 'helper/format';
import global from 'helper/global';

const BasketMessage = ({ basketMessage }) => {
    global.setCanUpdateCustomer(true)
    if (basketMessage.productCode) {
        const orderItem = basketMessage.order.orderItems.find(orderItem => orderItem.productCode === basketMessage.productCode);
        if (orderItem && typeof orderItem.product !== 'undefined') {
            return <div className="basket-message">
                {orderItem.product.image && <img src={orderItem.product.image} alt={orderItem.product.title}/>}
                <div>
                    { basketMessage.quantity > 0 && <h3>{basketMessage.quantity} x {orderItem.product.title}</h3> }
                    { basketMessage.returnQuantity > 0 && <p>{basketMessage.returnQuantity} x return{basketMessage.returnQuantity !== 1 ? 's' : ''} of { orderItem.product.title }</p> }
                </div>
            </div>;
        }
    } else if (basketMessage.returns) {
        let returnCount = 0;
        Object.entries(basketMessage.returns).forEach(([code, returnQuantity]) => {
            returnCount += returnQuantity;
        });
        return <div className="basket-message">
            <h3>{ returnCount } return{ returnCount > 1 ? 's' : ''} added to basket</h3>
        </div>;
    } else if (basketMessage.repeatedOrder) {
        return <div className="basket-message">
            <h3>Items from your previous order have been added to the basket</h3>
            { basketMessage.differences.length > 0 && <>
                <p><strong>Differences since your previous order:</strong></p>
                { basketMessage.differences.map((difference, index) => {
                    return <p key={ index } className={ 'basket-message__price-' + (difference.difference > 0 ? 'in' : 'de') + 'crease' }>{ difference.product.title } price has <strong>{ difference.difference > 0 ? 'in' : 'de' }creased by { format.currency(Math.abs(difference.difference)) }</strong></p>
                }) }
            </> }
        </div>;
    }
    return <></>
}

export default BasketMessage;