import format from 'helper/format';

const MockDataElement = (props) => {
    const Element = (props.element ? props.element : 'div');
    let className = 'mock-data';
    if (props.randomise) {
        className += ' mock-data--randomise';
    }
    if (props.inline) {
        className += ' mock-data--inline';
    }
    if (props.round) {
        className += ' mock-data--round';
    }
    if (props.data && props.data !== null && props.item && props.item.length) {
        let value = '';
        if (props.item.includes('.')) {
            let itemArray = props.item.split('.');
            if (typeof props.data[itemArray[0]] !== 'undefined' && props.data[itemArray[1]] !== 'undefined') {
                value = props.data[itemArray[0]][itemArray[1]];
            }
        } else if (typeof props.data[props.item] !== 'undefined' && props.data[props.item] !== null) {
            value = props.data[props.item];
        }
        if (props.dataFormat) {
            value = format[props.dataFormat](value);
        }
        return (props.prefix ? props.prefix : '') + value + (props.postfix ? props.postfix : '');
    }
    return (
        <Element className={ className }><span className="mock-data__inner" style={ props.style }/></Element>
    );
}

export default MockDataElement;