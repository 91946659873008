import React from 'react';
import parse from 'html-react-parser';
import { getChildren } from 'helper/form/form';
import url from 'helper/url';
import { Link } from 'react-router-dom';

const getHref = (href) => {
    const replace = {
        '/account/contact': '/help-support/contact',
        '/contact-us': '/help-support/contact',
        'https://my.flogas.co.uk/account/contact': '/help-support/contact',
    }
    if (typeof replace[href] !== 'undefined') {
        href = replace[href];
    } else if (/^(ftp|tel|mailto):/.test(href)) {
        // Leave href as is
    } else if (href.substr(0, 27) === '/frequently-asked-questions') {
        href = url.get('faq', href.replace('/frequently-asked-questions/', ''));
    } else if (href.substr(0, 30) === 'https://www.youtube.com/embed/') {
        href = 'https://www.youtube.com/watch?v=' + href.substr(30);
    } else if (!url.isPortalBasePath(href) && href.substr(0, 1) === '/') {
        href = 'https://www.flogas.co.uk' + href;
    }
    return href;
}
const getChild = (child) => {
    let newChildren;
    const children = getChildren(child);
    if (children) {
        newChildren = getLinkedHtml(children);
    }
    if (child.type === 'a' && typeof child.props.href !== 'undefined') {
        const href = getHref(child.props.href);
        if (/^(ftp|tel|mailto):/.test(href) || (/^(https?):\/\//.test(href) && !url.isPortalBasePath(href))) {
            return React.cloneElement(child, { ...child.props, href: href, target: '_blank' }, child.props.children);
        } else {
            return <Link key={ child.key } to={ href }>{ child.props.children }</Link>
        }
    }
    if (child.type === 'img') {
        if (child.props.src.substr(0, 1) === '/') {
            // Images imported from original core site that aren't on S3
            return React.cloneElement(child, { ...child.props, src: 'https://www.flogas.co.uk' + child.props.src }, child.props.children);
        }
    }
    if (children) {
        return React.cloneElement(child, { ...child.props }, newChildren);
    }
    return child;
}
const getLinkedHtml = (children) => {
    if (Array.isArray(children)) {
        return children.map(child => {
            return getChild(child);
        });
    } else if (typeof children === 'object') {
        return getChild(children);
    } else {
        return children;
    }
}

export const getHtml = (htmlString) => {
    if (typeof htmlString === 'string') {
        let html = parse(htmlString.replace('\n', ''));
        return getLinkedHtml(html);
    }
    return '';
}