import React, { createContext, useState } from 'react';


const TabsContext = createContext({});

export const TabsContextProvider = (props) => {
    const [tabs, setTabs] = useState({});

    return <TabsContext.Provider
        value={ {
            tabs: tabs,
            setTabs: setTabs,
        } }>{ props.children }</TabsContext.Provider>;
}


export default TabsContext;