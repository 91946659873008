import React from 'react';
import { getAllowedAttributes } from 'helper/form/fields';

const Checkbox = (props) => {
    const allowedAttributes = [
        'name',
        'value',
        'disabled',
        ...props.allowedAttributes
    ];
    const attributes = getAllowedAttributes(props, allowedAttributes);
    return (
        <div className="form__field-checkbox">
            <input type="checkbox" id={ props.name } { ...attributes } onChange={ props.inputChangeHandler } onFocus={ props.inputChangeHandler } value="1" checked={ props.value === '1' }/>
            <label htmlFor={ props.name }>{ props.label }</label>
        </div>
    );
};

export default Checkbox;