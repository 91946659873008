import log from 'helper/log';
import debug from 'helper/debug';

export const axios = require('axios');
export const apiClient = axios.create({
    headers: {
        Accept: 'application/json'
    },
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-CSRF-TOKEN'
});
apiClient.interceptors.request.use((config) => {
    config.headers['request-startTime'] = new Date().getTime();
    return config
})

apiClient.interceptors.response.use((response) => {
    const currentTime = new Date().getTime()
    const startTime = response.config.headers['request-startTime']
    response.headers['request-duration'] = currentTime - startTime
    return response
})
export const legacyApiClient = axios.create({
    headers: {
        Accept: 'application/json'
    },
    withCredentials: true,
    baseURL: process.env.REACT_APP_LEGACY_API_URL
});

// Wrapping class to work with apiClient methods but allows automatically fixed URLs and snake cased params
export const api = {
    debug: process.env.NODE_ENV === 'development',
    cache: {},
    setCache(response, timeout = 5) {
        // Only set the cache if it didn't come back from the cache
        if (!response.cache) {
            response.data.response._cacheExpiry = null;
            if (timeout) {
                const now = new Date();
                response.data.response._cacheExpiry = new Date(now.getTime() + (timeout * 60000));
            }
            if (typeof this.cache[log.customer.accountNumber] === 'undefined') {
                this.cache[log.customer.accountNumber] = {};
            }
            this.cache[log.customer.accountNumber][this.removeDebugParamsFromUrl(response.config.url)] = response.data.response;
        }
    },
    getCache(url) {
        const cache = (this.cache[log.customer.accountNumber] ? this.cache[log.customer.accountNumber][url] : null);
        if (cache) {
            if (cache._cacheExpiry === null) {
                return true;
            } else {
                const now = new Date();
                if (now.getTime() < cache._cacheExpiry.getTime()) {
                    return true;
                }
            }
        }
        return false;
    },
    cacheClear(path) {
        if (this.cache[log.customer.accountNumber]) {
            if (path.substr(0, 7) !== 'portal/') {
                path = 'portal/' + path;
            }
            Object.entries(this.cache[log.customer.accountNumber]).forEach(([cacheKey, cacheValue]) => {
                if (cacheKey.substr(0, path.length) === path) {
                    delete api.cache[log.customer.accountNumber][cacheKey];
                }
            });
        }
    },
    flushCache() {
        this.cache = {};
    },
    // Return a fake promise with the same response schema axios uses (only including what's needed)
    getPromise(url, data) {
        return Promise.resolve({
            data: { response: data },
            cache: true,
            status: 200,
            statusText: 'OK',
            headers: { Accept: 'application/json' },
            config: { url: url, method: 'get' }
        });
    },
    getUrl(url, params = null) {
        let urlArray = url.split('/');
        if (urlArray[0] !== 'sanctum' && urlArray[0] !== 'portal') {
            urlArray = ['portal'].concat(urlArray);
            url = urlArray.join('/');
        }
        if (this.debug) {
            if (!params) {
                params = {};
            }
            params['XDEBUG_SESSION_START'] = 'PHPSTORM';
        }
        if (params) {
            let urlParams = Object.keys(params).filter(key => params[key] && params[key].toString().length).map(key => key + '=' + params[key]).join('&');
            return url + '?' + urlParams;
        }
        return url;
    },
    removeDebugParamsFromUrl(url) {
        if (!this.debug || url.indexOf('?') === -1) {
            return url;
        }

        let searchParams = new URLSearchParams(url.slice(url.indexOf('?')));
        searchParams.delete('XDEBUG_SESSION_START');
        return url.slice(0, url.indexOf('?')) + (searchParams.size ? '?' + searchParams.toString() : '');
    },
    getParams(params) {
        return params;
    },
    getLegacyParams(params) {
        const formData = new FormData();
        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                formData.append(key, value);
            });
        }
        return formData;
    },
    getOptions(options) {
        if (options && options.nocache) {
            options.header.nocache = true;
        }
        return options;
    },
    post(url, params, options) {
        return apiClient.post(this.getUrl(url), this.getParams(params), this.getOptions(options));
    },
    legacyPost(url, params, options) {
        if (process.env.REACT_APP_LEGACY_API_URL) {
            return legacyApiClient.post(url, this.getLegacyParams(params), this.getOptions(options));
        }
    },
    get(url, params, options) {
        let cacheUrl = this.removeDebugParamsFromUrl(this.getUrl(url, params));
        if (this.getCache(cacheUrl)) {
            return this.getPromise(cacheUrl, this.cache[log.customer.accountNumber][cacheUrl]);
        }
        return apiClient.get(this.getUrl(url, params), this.getOptions(options));
    },
    patch(url, params, options) {
        return apiClient.patch(this.getUrl(url), this.getParams(params), this.getOptions(options));
    },
    delete(url, params, options) {
        options = this.getOptions(options);
        if (params) {
            if (!options) {
                options = {};
            }
            options.data = params;
        }
        return apiClient.delete(this.getUrl(url), this.getOptions(options));
    },
    // Wrap all form submissions .then responses with this
    success(response, form = { setErrorMessage: (message) => { }, enableForm: () => {}, scrollToForm: () => {} }, runActions = true) {
        window.localStorage.setItem('maintenanceMode', 'false');
        const { setErrorMessage, enableForm, scrollToForm } = form;
        if (runActions) {
            enableForm();
            scrollToForm();
        }
        if (response.data.errors) {
            setErrorMessage(response.data.errors[Object.keys(response.data.errors)[0]]);
            return false;
        } else {
            // Clear the error message, if there is one
            setErrorMessage('');
        }
        return true;
    },
    // Wrap all form submissions .catch responses with this
    error(error, form = { setErrorMessage: (message) => { }, setSuccessMessage: (message) => { }, enableForm: () => {}, scrollToForm: () => {} }, defaultErrorMessage = '') {
        if (error.response) {
            debug.error(error.response)
            const { setErrorMessage, setSuccessMessage, enableForm, scrollToForm } = form;
            enableForm();
            scrollToForm();
            if (typeof error.response.status !== 'undefined') {
                switch (error.response.status) {
                    case 401:
                        window.localStorage.setItem('authenticated', 'false');
                        window.location = '/';
                        break;
                    case 419: // Set the CSRF if it has expired
                        api.get('sanctum/csrf-cookie');
                        break;
                    case 429: // Rate limiting
                        setErrorMessage('You have been rate limited for sending too many requests');
                        setSuccessMessage('');
                        break;
                    case 403: // Rate limiting
                        setErrorMessage('You don\'t have permission to do that');
                        setSuccessMessage('');
                        break;
                    case 503:
                        window.localStorage.setItem('maintenanceMode', 'true');
                        if (window.location.pathname !== '/') {
                            window.location = '/';
                        }
                        break;
                    default:
                        if (defaultErrorMessage.length) {
                            setErrorMessage(defaultErrorMessage);
                            setSuccessMessage('');
                        }
                }
            }
        }
    },
    setPerformanceMetrics(response) {
        if (response.data.performance) {
            debug.showPerformanceMetrics(response);
        }
    }
};