import React, { useEffect, useState } from 'react';
import Icon from 'components/ui/Icon';
import { api, axios } from 'helper/api/client';
import setting from 'helper/setting';
import log from 'helper/log';

let interval;

const LiveChat = (props) => {
    let liveChatTimeout = setting.get('liveChat.interval', 60); // Seconds before checking for Live chat
    if (liveChatTimeout < 60) {
        liveChatTimeout = 60; // Prevent it being set too low
    }
    const [liveChatUrl, setLiveChatUrl] = useState('');
    const enabled = setting.get('liveChat.enabled', false);
    useEffect(() => {
        let source = null;
        clearInterval(interval);
        if (enabled && props.authorised) {
            source = axios.CancelToken.source();
            interval = setInterval(() => {
                api.get('live-chat/url')
                    .then(response => {
                        setLiveChatUrl(response.data.response.url);
                    }).catch(error => {
                        if (liveChatUrl) {
                            setLiveChatUrl('');
                        }
                        api.error(error);
                    });
            }, liveChatTimeout * 1000);
        } else if (liveChatUrl) {
            setLiveChatUrl('');
        }
        return () => {
            clearInterval(interval);
            source?.cancel();
        }
    }, [enabled, props.authorised, liveChatTimeout]);
    const openLiveChat = () => {
        if (liveChatUrl) {
            const width = 600;
            const height = 600;
            const left = (window.screen.width / 2) - (width / 2);
            const top = (window.screen.height / 2) - (height / 2);
            log.action('Live chat', 'Opened', 'live_chat');
            window.open(liveChatUrl, 'portalLiveChat', 'scrollbars=yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);
        }
    }
    let className = 'live-chat' + (liveChatUrl !== '' ? ' live-chat--available' : '');
    if (!props.authorised || !enabled) {
        clearInterval(interval);
        return null;
    }
    if (liveChatUrl === false || liveChatUrl.length === 0) {
        return null;
    }
    return (
        <div className={ className }>
            <button className="live-chat__button" onClick={ openLiveChat }><span className="live-chat__button-inner"><Icon icon="LiveChat"/>Live chat</span></button>
        </div>
    )
}

export default LiveChat;