import 'url-search-params-polyfill';

const emailMismatch = (response) => {
    let params = { emailMismatchToken: response.token, ...response.emailMismatch }
    params = Object.keys(params).reduce((object, key) => {
        if (params[key] !== null && params[key].length) {
            object[key] = params[key];
        }
        return object
    }, {});
    const urlSearchParams = new URLSearchParams(params);
    window.localStorage.setItem('authenticated', 'false');
    window.location = '/auth?' + urlSearchParams.toString();
}

export default emailMismatch;