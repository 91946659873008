import { useContext } from 'react';
import UserContext from 'context/user';

import 'scss/component/admin-bar/admin-bar.scss';

const AdminBar = () => {
    const { user } = useContext(UserContext);
    if (!user.isDefault) {
        const adminId = window.localStorage.getItem('_adminId');
        if (adminId && adminId !== '') {
            document.body.classList.add('body--admin-bar');
            return (
                <div className="admin-bar">
                    <p>Logged in as customer <strong>{ user.accountNumber }</strong><br /><span className="admin-bar__name"> { user.contact.firstName } { user.contact.surname }</span></p>
                </div>
            )
        }
    } else {
        document.body.classList.remove('body--admin-bar');
    }
    return null;
}

export default AdminBar;