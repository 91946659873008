import React from 'react';
import { getClassName } from 'helper/bem';

const getField = (name) => {
    return require('components/form/field/' + name).default;
}

const FormField = (props) => {
    const getFormField = (props) => {
        return React.createElement(getField(props.type), { ...props, allowedAttributes: [] });
    }
    let classNames = '';
    if (props.className) {
        classNames += ' ' + props.className;
    }
    if (!props.valid) {
        classNames += ' invalid';
    }
    if (!props.visible) {
        classNames += ' hidden';
    }
    const required = props.required && <abbr>*</abbr>

    if (props.type === 'Hidden') {
        return (
            <>
                { getFormField(props) }
            </>
        );
    }
    return (
        <>
            { props.before && props.before }
            <div className={ getClassName('form__field', classNames) }>
                { props.type !== 'Checkbox' && props.showLabel && <label className="form__field-label">{ props.label } { required }</label> }
                { props.afterLabel && <p className="form__field-label-after">{ props.afterLabel }</p> }
                { props.fieldBefore && props.fieldBefore }
                { getFormField(props) }
                { props.fieldAfter && props.fieldAfter }
                { !props.valid && <div className={ 'form__field-error-message' }>{ props.validationMessage }</div> }
            </div>
            { props.after && props.after }
        </>
    );
};

FormField.defaultProps = {
    showLabel: true
}

export default FormField;