//import React, {useState} from 'react';
//import { api } from 'helper/api/client';

import { useLocation } from 'react-router-dom';
import Hero from 'components/partial/Hero';
import Box from 'components/ui/Box';

const NotFound = () => {
    const location = useLocation();
    return (
        <>
            <Hero title="404 Not found"/>
            <Box>
                <p>The URL you requested does not exist.</p>
                <p><code>{ location.pathname }</code></p>
            </Box>
        </>
    );
};

export default NotFound;