import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AccountsContext from 'context/accounts';
import Button from 'components/ui/Button';
import { getClassName } from 'helper/bem';

const FavouriteButton = (props) => {
    const { accounts, setAccounts } = useContext(AccountsContext);
    const favouriteSuccess = (response, apiData) => {
        const newAccounts = accounts.map(account => {
            if (account.id === apiData.id) {
                account.favourite = !account.favourite;
            }
            return account;
        });
        setAccounts(newAccounts);
    }
    const classNames = (props.account.favourite ? ' favourited' : '') + (props.className ? ' ' + props.className : '');
    return (
        <Button
        apiAction="customer/accounts/update"
        apiData={ { id: props.account.id, favourite: !props.account.favourite } }
        apiVerb="post"
        apiSuccess={ favouriteSuccess }
        iconOnly={ true }
        fullClassName={ getClassName('favourite-button', classNames )}
        size="small" outline={ true }
        icon={  (props.account.favourite ? 'Favourite' : 'FavouriteNot') } />
    );
}

FavouriteButton.propTypes = {
    account: PropTypes.object.isRequired,
}

export default FavouriteButton;